import React from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Typography,
  Paper,
  Link
} from "@material-ui/core";
import { OutboundLink } from "react-ga";

import CostVsQualityChart from "./CostVsQualityChart";

const styles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24
  }
});

const Search = ({ classes }: WithStyles<typeof styles>) => (
  <Paper className={classes.root}>
    <Typography variant="h5" gutterBottom>
      Cost vs Quality
    </Typography>
    <Typography variant="body1" gutterBottom>
      There's <b>no correlation</b> between average hospital charge and quality
      of care. Below we plotted cost ratings (condition{" "}
      <Link
        color="secondary"
        component={(props: any) => (
          <OutboundLink
            {...props}
            eventLabel="z-score"
            to="https://www.khanacademy.org/math/statistics-probability/modeling-distributions-of-data/z-scores/a/z-scores-review"
            target="_blank"
          />
        )}
      >
        z-score
      </Link>{" "}
      means) and{" "}
      <Link
        color="secondary"
        component={(props: any) => (
          <OutboundLink
            {...props}
            eventLabel="cms-rating"
            to="https://www.medicare.gov/hospitalcompare/Data/Hospital-overall-ratings-calculation.html"
            target="_blank"
          />
        )}
      >
        CMS quality ratings
      </Link>
      .
    </Typography>
    <CostVsQualityChart />
  </Paper>
);

export default withStyles(styles)(Search);
