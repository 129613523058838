import React, { ReactNode } from "react";
import {
  Typography,
  withStyles,
  createStyles,
  Theme,
  WithStyles,
  Paper
} from "@material-ui/core";
import { withRouter, RouteComponentProps, generatePath } from "react-router";

import Link from "./Link";
import { ChargesPathProps, BreakdownType } from "../constants";

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    header: {
      paddingTop: 16,
      paddingBottom: 8,
      paddingLeft: 24,
      paddingRight: 24,
      backgroundColor: palette.grey[100],
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    showAverage: {
      marginRight: 16
    }
  });

interface Props
  extends WithStyles<typeof styles>,
    RouteComponentProps<ChargesPathProps> {
  title: ReactNode;
  hasBreakdownToggle?: boolean;
}

const ToggleBreakdown = ({
  match,
  title,
  hasBreakdownToggle,
  classes
}: Props) => (
  <Paper className={classes.root}>
    <div className={classes.header}>
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      {hasBreakdownToggle ? (
        <div>
          <Typography variant="caption">
            {match.params.breakdown === BreakdownType.average ? (
              <span className={classes.showAverage}>SHOWING AVERAGE COST</span>
            ) : (
              <Link
                className={classes.showAverage}
                to={generatePath(match.path, {
                  ...match.params,
                  breakdown: BreakdownType.average
                })}
              >
                VIEW AVERAGE COST
              </Link>
            )}
            {match.params.breakdown === BreakdownType.breakdown ? (
              <span>SHOWING COST BREAKDOWN</span>
            ) : (
              <Link
                className={classes.showAverage}
                to={generatePath(match.path, {
                  ...match.params,
                  breakdown: BreakdownType.breakdown
                })}
              >
                VIEW COST BREAKDOWN
              </Link>
            )}
          </Typography>
        </div>
      ) : null}
    </div>
  </Paper>
);

export default withStyles(styles)(withRouter(ToggleBreakdown));
