import React from "react";
import { Paper, withStyles, createStyles, WithStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router";
import { TableCellProps, Index } from "react-virtualized";

import { conditionTypeToName } from "../../constants";
import Link from "../../components/Link";
import { CategoriesPathProps } from ".";
import { parseId, getChargesPath } from "../../utils";
import VirtualizedTable, {
  VirtualizedTableColumn
} from "../../components/VirtualizedTable";
import { ConditionsComponent, ConditionsQuery } from "../../generated/graphql";

const styles = createStyles({
  root: {
    flex: 1,
    minHeight: 300,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  conditionName: {
    display: "box",
    lineClamp: 4,
    boxOrient: "vertical",
    overflow: "hidden"
  }
});

const getConditions = (data: ConditionsQuery | undefined) =>
  data ? (data.conditions ? data.conditions : []) : [];

const ConditionsTable = ({
  match,
  classes
}: WithStyles<typeof styles> & RouteComponentProps<CategoriesPathProps>) => (
  <Paper className={classes.root}>
    <ConditionsComponent
      variables={{ id: parseId(match.params.id) as number }}
      skip={!match.params.id || !parseId(match.params.id)}
    >
      {({ data }) => {
        const conditions = getConditions(data);

        const columns: VirtualizedTableColumn[] = [
          {
            dataKey: "name",
            label: "CONDITION",
            width: 200,
            flexGrow: 1.0,
            cellContentRenderer: ({ rowIndex }: TableCellProps) => {
              const condition = conditions[rowIndex];
              return (
                <div className={classes.conditionName}>
                  {condition.name} ({conditionTypeToName[condition.type]})
                </div>
              );
            }
          },
          {
            dataKey: "hospitals",
            label: "COMPARE COSTS",
            cellContentRenderer: ({ rowIndex }: TableCellProps) => {
              const condition = conditions[rowIndex];
              return (
                <Link to={getChargesPath({ id: condition.id.toString() })}>
                  Show Hospitals
                </Link>
              );
            },
            width: 200
          }
        ];

        return (
          <VirtualizedTable
            rowCount={conditions.length}
            rowGetter={({ index }: Index) => conditions[index]}
            columns={columns}
            rowHeight={80} // Needs to be taller for mobile
            headerHeight={56}
          />
        );
      }}
    </ConditionsComponent>
  </Paper>
);

export default withStyles(styles)(withRouter(ConditionsTable));
