import React from "react";
import { ValueType } from "react-select/lib/types";
import { withRouter, RouteComponentProps } from "react-router";
import { Props as ReactSelectProps } from "react-select/lib/Creatable";
import ReactGA from "react-ga";

import {
  ChargesPathProps,
  ChargesPathProp,
  chargesPathPropToDefaultParam,
  chargesPathPropToDefaultValue
} from "../constants";
import { getChargesPath } from "../utils";
import SelectUrlParam, { SelectOption } from "./SelectUrlParam";

interface Props
  extends ReactSelectProps<SelectOption>,
    RouteComponentProps<ChargesPathProps> {
  pathProp: ChargesPathProp;
  options: SelectOption[];
}

const SelectChargesParam = ({
  history,
  match,
  pathProp,
  options,
  value,
  ...other
}: Props) => (
  <SelectUrlParam
    options={options}
    onChange={(selectOption: ValueType<SelectOption>) => {
      let label = "",
        value = "";
      if (selectOption && !Array.isArray(selectOption)) {
        label = selectOption.label;
        value = selectOption.value;
      }
      let { id, county, state } = match.params;
      if (pathProp === ChargesPathProp.id) {
        id = value || chargesPathPropToDefaultParam[ChargesPathProp.id];
      } else if (pathProp === ChargesPathProp.state) {
        state = value || chargesPathPropToDefaultParam[ChargesPathProp.state];
        county = chargesPathPropToDefaultParam[ChargesPathProp.county];
      } else if (pathProp === ChargesPathProp.county) {
        county = value || chargesPathPropToDefaultParam[ChargesPathProp.county];
      }

      const newUrl = getChargesPath({
        ...match.params,
        id,
        state,
        county
      });
      ReactGA.pageview(newUrl);
      ReactGA.event({
        category: "Compare",
        action: `Select ${label}`,
        label: value
      });

      history.push(newUrl);
    }}
    value={
      value ||
      options.find(
        op =>
          op.value ===
          (match.params[pathProp] || chargesPathPropToDefaultValue[pathProp])
      )
    }
    {...other}
  />
);

export default withRouter(SelectChargesParam);
