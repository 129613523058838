import React from "react";
import { withStyles, createStyles, Theme, WithStyles } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const maxStars = 5;

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    icon: {
      color: palette.grey[700]
    }
  });

interface Props extends WithStyles<typeof styles> {
  hospital: { rating?: number | null };
}

const HospitalRating = ({ hospital, classes }: Props) => {
  if (!hospital.rating) {
    return null;
  }
  const stars = [];
  for (let i = 0; i < maxStars; i++) {
    if (i < hospital.rating) {
      stars.push(<StarIcon key={i} className={classes.icon} />);
    } else {
      stars.push(<StarBorderIcon key={i} className={classes.icon} />);
    }
  }
  return <div className={classes.root}>{stars}</div>;
};

export default withStyles(styles)(HospitalRating);
