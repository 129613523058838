import React from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

import { Hospital } from "../../generated/graphql";

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    root: {
      flex: 1,
      minHeight: 300,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      [breakpoints.down("md")]: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }
    },
    text: {
      [breakpoints.down("sm")]: {
        margin: 16
      },
      [breakpoints.up("md")]: {
        margin: 64
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  hospital: Pick<Hospital, "name" | "inSet" | "hasCdm">;
}

const DataInfo = ({ hospital, classes }: Props) => {
  let text = `We haven't looked for ${
    hospital.name
  } data yet. Check back soon!`;
  if (hospital.inSet) {
    if (hospital.hasCdm) {
      text = `We found chargemaster data for ${
        hospital.name
      } but we couldn't find diagnosis-related group (DRG) data which populates this site.`;
    } else {
      text = `We were unable to locate data for ${
        hospital.name
      }. They may be non-compliant with the CMS regulation.`;
    }
  }
  return (
    <Paper className={classes.root}>
      <Typography variant="subtitle1" className={classes.text}>
        {text}
      </Typography>
    </Paper>
  );
};

export default withStyles(styles)(DataInfo);
