import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { ValueType } from "react-select/lib/types";

import { ChargesPathProp, chargesPathPropToDefaultValue } from "../constants";
import { SelectOption } from "./SelectUrlParam";
import SelectChargesParam from "./SelectChargesParam";
import { StatesComponent, StatesQuery } from "../generated/graphql";

const styles = createStyles({
  select: {
    flex: "0 0 auto",
    paddingLeft: 8,
    paddingRight: 8,
    width: 220
  }
});

interface Props extends WithStyles<typeof styles> {
  isDisabled?: boolean;
  value?: ValueType<SelectOption>;
}

const allStates: Readonly<SelectOption> = {
  label: "All States",
  value: chargesPathPropToDefaultValue[ChargesPathProp.state]
};

const getStateOptions = (data: StatesQuery | undefined) => {
  let countyOptions: SelectOption[] = [];
  if (data && data.states) {
    countyOptions = data.states.map(state => {
      return {
        value: state,
        label: state
      };
    });
  }
  countyOptions.unshift(allStates);
  return countyOptions;
};

const SelectState = ({ value, isDisabled, classes }: Props) => (
  <StatesComponent>
    {({ data }) => (
      <SelectChargesParam
        className={classes.select}
        pathProp={ChargesPathProp.state}
        options={getStateOptions(data)}
        isDisabled={isDisabled}
        value={value}
      />
    )}
  </StatesComponent>
);

export default withStyles(styles)(SelectState);
