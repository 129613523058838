import React from "react";
import {
  Typography,
  withStyles,
  createStyles,
  Theme,
  WithStyles,
  Paper,
  Link,
  Tooltip
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import HelpIcon from "@material-ui/icons/Help";

import { OutboundLink } from "react-ga";
import HospitalRating from "../../components/HospitalRating";
import { Hospital } from "../../generated/graphql";

const styles = ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      [breakpoints.up("lg")]: {
        width: 400
      },
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    header: {
      paddingTop: 16,
      paddingBottom: 8,
      paddingLeft: 24,
      paddingRight: 24,
      backgroundColor: palette.grey[100]
    },
    hospitalName: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    link: {
      display: "flex",
      alignItems: "center"
    },
    arrowRightAltIcon: {
      marginLeft: 2,
      fontSize: 18
    },
    rating: {
      display: "flex",
      alignItems: "center",
      marginBottom: spacing.unit
    },
    ratingNumber: {
      marginRight: 8
    },
    stars: {
      display: "flex"
    },
    icon: {
      color: palette.grey[700]
    }
  });

const formatPhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

interface Props extends WithStyles<typeof styles> {
  hospital: Pick<
    Hospital,
    | "id"
    | "name"
    | "rating"
    | "ratingNotes"
    | "phoneNumber"
    | "phoneNumber"
    | "address"
    | "zipCode"
    | "city"
    | "state"
  >;
}

const AboutHospital = ({ hospital, classes }: Props) => (
  <Paper className={classes.root}>
    <div className={classes.header}>
      <Link
        color="secondary"
        variant="body2"
        component={(props: any) => (
          <OutboundLink
            {...props}
            eventLabel={hospital.id.toString()}
            // Goto first search result (I'm feeling lucky).
            to={`http://www.google.com/search?q=${hospital.name} ${
              hospital.name
            }&btnI`}
            target="_blank"
          />
        )}
        style={{ cursor: "pointer" }}
        className={classes.link}
      >
        website
        <ArrowRightAltIcon className={classes.arrowRightAltIcon} />
      </Link>
      <Typography variant="h5" gutterBottom className={classes.hospitalName}>
        {hospital.name}
      </Typography>
      <div className={classes.rating}>
        <Typography variant="h4" className={classes.ratingNumber}>
          {hospital.rating ? hospital.rating : "N/A"}
        </Typography>
        {hospital.rating ? (
          <div>
            <HospitalRating hospital={hospital} />
            <Typography variant="caption">CMS quality rating</Typography>
          </div>
        ) : (
          <Tooltip
            title={
              <>
                <Typography color="inherit">
                  CMS doesn't provide a quality rating
                </Typography>
                {hospital.ratingNotes}
              </>
            }
          >
            <HelpIcon className={classes.icon} />
          </Tooltip>
        )}
      </div>
      <Typography variant="body2" gutterBottom>
        {formatPhoneNumber(hospital.phoneNumber)}
      </Typography>
      <Typography variant="body2">{hospital.address}</Typography>
      <Typography variant="body2" gutterBottom>
        {hospital.city}, {hospital.state} {hospital.zipCode}
      </Typography>
    </div>
  </Paper>
);

export default withStyles(styles)(AboutHospital);
