import React from "react";
import {
  Typography,
  withStyles,
  createStyles,
  Theme,
  WithStyles,
  Paper
} from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";

import { parseId } from "../../utils";
import { CategoriesPathProps } from ".";
import { ConditionCategoryComponent } from "../../generated/graphql";

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    header: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      backgroundColor: palette.grey[100]
    }
  });

const CategoryDescription = ({
  match,
  classes
}: WithStyles<typeof styles> & RouteComponentProps<CategoriesPathProps>) => (
  <Paper className={classes.root}>
    <ConditionCategoryComponent
      variables={{ id: parseId(match.params.id) as number }}
      skip={!match.params.id || !parseId(match.params.id)}
    >
      {({ data }) => (
        <div className={classes.header}>
          <Typography variant="h6" gutterBottom>
            {data && data.conditionCategory && data.conditionCategory.name}
          </Typography>
        </div>
      )}
    </ConditionCategoryComponent>
  </Paper>
);

export default withStyles(styles)(withRouter(CategoryDescription));
