import React from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
  Typography
} from "@material-ui/core";

import Link from "../../components/Link";
import icon_data from "../../img/data.png";
import icon_average from "../../img/average.png";
import icon_locations from "../../img/locations.png";
import icon_patient from "../../img/patient.png";
import icon_price from "../../img/price.png";
import Header from "../../components/Header";
import { getChargesPath } from "../../utils";
import { OutboundLink } from "react-ga";

const styles = ({ mixins, breakpoints }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1
    },
    contentClass: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      [breakpoints.down("sm")]: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 16,
        paddingLeft: 16
      },
      [breakpoints.up("md")]: {
        paddingTop: 32,
        paddingBottom: 32,
        paddingRight: 64,
        paddingLeft: 64
      }
    },
    toolbar: mixins.toolbar,
    databaseHeader: {
      marginTop: 18
    },
    row: {
      display: "flex"
    },
    column: {
      flex: 1,
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
      marginTop: 20
    },
    icon: {
      width: 128,
      height: 128
    }
  });

const About = ({ classes }: WithStyles<typeof styles, true>) => (
  <>
    <Header />
    <div className={classes.root}>
      <div className={classes.contentClass}>
        <div className={classes.toolbar} />

        <Typography variant="h2" gutterBottom>
          Compare Hospitals
        </Typography>
        <Typography variant="h6" gutterBottom>
          The easiest way to compare hospital prices
        </Typography>
        <Typography variant="body1" gutterBottom>
          Compare Hospitals makes information about the price of hospital
          treatments available to everyone. See what hospitals in your area
          charge on average for procedures like{" "}
          <Link to={getChargesPath({ id: "1" })}>Heart Transplants</Link> or{" "}
          <Link to={getChargesPath({ id: "264" })}>Cesarean Section</Link>. Or,
          browse by diagnosis, such as{" "}
          <Link to={getChargesPath({ id: "308" })}>Septicemia</Link>,{" "}
          <Link to={getChargesPath({ id: "237" })}>Kidney Stones</Link>, or{" "}
          <Link to={getChargesPath({ id: "36" })}>Concussions</Link>.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Email us with feedback, questions or concerns{" "}
          <Link
            color="secondary"
            component={(props: any) => (
              <OutboundLink
                {...props}
                eventLabel="email"
                to="mailto:info@comparehospitals.org"
              />
            )}
            style={{ cursor: "pointer" }}
          >
            info@comparehospitals.org
          </Link>
          .
        </Typography>
        <Typography variant="h6" className={classes.databaseHeader}>
          About our data
        </Typography>
        <div className={classes.row}>
          <img src={icon_patient} alt="patient" className={classes.icon} />
          <div className={classes.column}>
            <Typography variant="subtitle1" gutterBottom>
              Per Hospital Visit
            </Typography>
            <Typography variant="body1" gutterBottom>
              Prices are inclusive of everything involved in care for a single
              hospital admission, including diagnostic procedures, treatment,
              medications, and more.
            </Typography>
          </div>
        </div>
        <div className={classes.row}>
          <img src={icon_average} alt="average" className={classes.icon} />
          <div className={classes.column}>
            <Typography variant="subtitle1" gutterBottom>
              Averages
            </Typography>
            <Typography variant="body1" gutterBottom>
              For each condition, we show an average price across all patients
              primarily admitted for that condition last year. Differences
              between hospitals may be due to patients requiring different
              levels of care, or doctors ordering different amounts of
              procedures.
            </Typography>
          </div>
        </div>
        <div className={classes.row}>
          <img src={icon_price} alt="price" className={classes.icon} />
          <div className={classes.column}>
            <Typography variant="subtitle1" gutterBottom>
              Standard Prices
            </Typography>
            <Typography variant="body1" gutterBottom>
              They reflect a hospital's standard charges, which includes prices
              you may be billed if you are uninsured. They are not the amount
              your insurance company pays hospitals or the amount you will be
              required to contribute under your plan.
            </Typography>
          </div>
        </div>
        <div className={classes.row}>
          <img src={icon_data} alt="data" className={classes.icon} />
          <div className={classes.column}>
            <Typography variant="subtitle1" gutterBottom>
              Organized from public sources
            </Typography>
            <Typography variant="body1" gutterBottom>
              Starting this year, the Center for Medicare and Medicaid Services
              (CMS) requires all hospitals to make publicly available
              information on their standard prices, including their pricing for
              all line items (chargemasters), and average charges grouped by
              diagnosis-related group. While publicly available, this
              information is often hard to find, in a variety of different
              formats, and hard to compare across hospitals.
            </Typography>
          </div>
        </div>
        <div className={classes.row}>
          <img src={icon_locations} alt="locations" className={classes.icon} />
          <div className={classes.column}>
            <Typography variant="subtitle1" gutterBottom>
              1,567 Hospitals
            </Typography>
            <Typography variant="body1" gutterBottom>
              We currently have data from 1,567 hospitals out of 4,792 in the
              United States (33% coverage). Some hospitals may be missing data
              if they have not yet complied with CMS regulations around data
              transparency. We are working to add more hospitals every day! If
              you see data from a hospital in your area missing and would like
              to see it added, please reach out to us.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default withStyles(styles)(About);
