import React from "react";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  TableCellProps,
  Index,
  SortDirectionType,
  RowMouseEventHandlerParams
} from "react-virtualized";
import { WithWidth, isWidthUp } from "@material-ui/core/withWidth";
import { withRouter, RouteComponentProps, match } from "react-router";
import { withWidth } from "@material-ui/core";

import { SortArgs } from "./ChargesContent";
import VirtualizedTable, { VirtualizedTableColumn } from "./VirtualizedTable";
import ComplicationsTooltip from "./ComplicationsTooltip";
import Link from "./Link";
import { ChargesPathProps, BreakdownType } from "../constants";
import { getHospitalsPath, formatCurrency } from "../utils";
import HospitalRating from "./HospitalRating";
import { Hospital } from "../generated/graphql";

export const averageChargeDataKey = BreakdownType.average;
export const cmsRatingDataKey = "rating";

const getCharge = (hospital: Hospital, complications: string) => {
  const charge = hospital.charges.find(charge =>
    charge.drg ? charge.drg.complications === complications : false
  );
  if (charge) {
    return formatCurrency(charge.amount);
  }
  return null;
};

const styles = createStyles({
  root: {
    flex: 1,
    minHeight: 300,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  hospitalName: {
    display: "box",
    lineClamp: 3,
    boxOrient: "vertical",
    overflow: "hidden"
  },
  ratingIcon: {
    fontSize: 16
  }
});

interface GetColumnsArgs extends WithWidth, WithStyles<typeof styles> {
  match: match<ChargesPathProps>;
  hospitals: Hospital[];
  complicationsList: string[];
}

const getColumns = ({
  width,
  match,
  hospitals,
  complicationsList,
  classes
}: GetColumnsArgs) => {
  const columns: VirtualizedTableColumn[] = [
    {
      dataKey: "name",
      label: "HOSPITAL NAME",
      cellContentRenderer: ({ rowIndex }: TableCellProps) => {
        const hospital = hospitals[rowIndex];
        return (
          <Link
            to={getHospitalsPath({ id: hospital.id.toString() })}
            className={classes.hospitalName}
          >
            {hospital.name}
          </Link>
        );
      },
      width: 180,
      flexGrow: 1.0,
      disableSort: true
    }
  ];
  if (
    complicationsList.length > 1 &&
    match.params.breakdown === BreakdownType.breakdown &&
    isWidthUp("md", width)
  ) {
    columns.push(
      ...complicationsList.map(complications => ({
        dataKey: complications,
        label: <ComplicationsTooltip complications={complications} />,
        cellContentRenderer: ({ rowIndex }: TableCellProps) =>
          getCharge(hospitals[rowIndex], complications),
        width: 180,
        disableSort: !hospitals.length
      }))
    );
  } else {
    columns.push(
      {
        dataKey: cmsRatingDataKey,
        label: "CMS RATING",
        cellContentRenderer: ({ rowIndex }: TableCellProps) =>
          typeof hospitals[rowIndex].rating === "number" ? (
            <HospitalRating
              hospital={hospitals[rowIndex]}
              classes={{ icon: classes.ratingIcon }}
            />
          ) : (
            "N/A"
          ),
        width: 180
      },
      {
        dataKey: averageChargeDataKey,
        label: "AVG CHARGE",
        cellContentRenderer: ({ rowIndex }: TableCellProps) => {
          const hospital = hospitals[rowIndex];
          return hospital.charges.length > 0
            ? formatCurrency(
                hospital.charges
                  .map(charge => charge.amount)
                  .reduce((sum, current) => sum + current) /
                  hospital.charges.length
              )
            : null;
        },
        width: 180,
        disableSort: !hospitals.length
      }
    );
  }
  return columns;
};

interface Props
  extends WithStyles<typeof styles>,
    RouteComponentProps<ChargesPathProps>,
    WithWidth {
  hospitals: Hospital[];
  complicationsList: string[];
  sort?: (info: SortArgs) => void;
  sortBy?: string;
  sortDirection?: SortDirectionType;
  handleRowMouseOver: (info: RowMouseEventHandlerParams) => void;
  handleRowMouseOut: (info: RowMouseEventHandlerParams) => void;
}

const ChargesTable = ({
  width,
  match,
  complicationsList,
  hospitals,
  sort,
  sortBy,
  sortDirection,
  handleRowMouseOver,
  handleRowMouseOut,
  classes
}: Props) => (
  <Paper className={classes.root}>
    <VirtualizedTable
      rowCount={hospitals.length}
      rowGetter={({ index }: Index) => hospitals[index]}
      columns={getColumns({
        width,
        match,
        hospitals,
        complicationsList,
        classes
      })}
      rowHeight={56}
      headerHeight={56}
      sort={sort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onRowMouseOver={handleRowMouseOver}
      onRowMouseOut={handleRowMouseOut}
    />
  </Paper>
);

export default withStyles(styles)(withWidth()(withRouter(ChargesTable)));
