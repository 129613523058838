import React from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme
} from "@material-ui/core/styles";

import { Feature, CountyProperties } from ".";
import { mapStops } from "./utils";
import { formatCurrency } from "../../utils";

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      margin: 8,
      padding: 4,
      background: palette.grey[700],
      opacity: 0.9,
      color: "white",
      maxWidth: 400,
      fontSize: 10,
      zIndex: 9,
      pointerEvents: "none"
    }
  });

interface Props extends WithStyles<typeof styles> {
  hoveredFeature: Feature<CountyProperties>;
  x?: number;
  y?: number;
}

const CountyTooltip = ({ classes, hoveredFeature, x, y }: Props) => (
  <div className={classes.root} style={{ left: x, top: y }}>
    <div>
      {hoveredFeature.properties.county}, {hoveredFeature.properties.state}
    </div>
    <div>
      Average Charge:{" "}
      {formatCurrency(hoveredFeature.properties.averageCharge || 0)}
    </div>
    <div>
      Percentile:{" "}
      {(
        (hoveredFeature.properties.percentile &&
          hoveredFeature.properties.percentile / (mapStops.length - 1)) ||
        0
      ).toLocaleString(undefined, {
        style: "percent"
      })}
    </div>
  </div>
);

export default withStyles(styles)(CountyTooltip);
