import { fromJS, List } from "immutable";
import { range } from "d3-array";
import { scaleQuantile } from "d3-scale";
import { red, green, lime, amber } from "@material-ui/core/colors";

import { CountyProperties, Feature, FeatureCollection } from ".";
import { Hospital } from "../../generated/graphql";

export const mapStops = [
  [0, green[500]],
  [1, lime[500]],
  [2, amber[500]],
  [3, red[500]]
];

const updatePercentiles = (
  featureCollection: FeatureCollection<CountyProperties>,
  accessor: (f: Feature<CountyProperties>) => number
) => {
  const { features } = featureCollection;
  const scale = scaleQuantile()
    .domain(features.map(accessor))
    .range(range(mapStops.length));
  features.forEach(f => {
    const value = accessor(f);
    f.properties.value = value;
    f.properties.percentile = scale(value);
  });
};

interface GetKeyArgs {
  county?: string;
  state?: string;
}

const getKey = ({ county, state }: GetKeyArgs) => `${county}$$${state}`;

export type CountyHospital = Pick<
  Hospital,
  "id" | "name" | "latitude" | "longitude" | "charges" | "county" | "state"
>;

export const getCountyFeatures = (
  usCountiesGeoJson: object,
  hospitals: CountyHospital[]
) => {
  if (!usCountiesGeoJson) {
    return List();
  }

  const countyToAmount = new Map<
    string,
    { average: number; quantity: number }
  >();
  for (const hospital of hospitals) {
    if (!hospital.charges || !hospital.county || !hospital.state) {
      continue;
    }
    const key = getKey(hospital);
    const amount = countyToAmount.get(key) || {
      average: 0,
      quantity: 0
    };
    countyToAmount.set(key, {
      average:
        (amount.average * amount.quantity +
          hospital.charges
            .map(charge => charge.amount)
            .reduce((total, amount) => total + amount)) /
        (amount.quantity + hospital.charges.length),
      quantity: amount.quantity + hospital.charges.length
    });
  }

  let features: Feature<CountyProperties>[] = [];
  if (countyToAmount.size > 1) {
    // Make a copy of the geo json feature collection
    const countyMapData: FeatureCollection<CountyProperties> = JSON.parse(
      JSON.stringify(usCountiesGeoJson)
    );
    countyMapData.features = countyMapData.features
      .filter(feature => countyToAmount.has(getKey(feature.properties)))
      .map(feature => {
        const key = getKey(feature.properties);
        const amount = countyToAmount.get(key);
        if (feature && amount && key) {
          feature.properties.averageCharge = amount.average;
        }
        return feature;
      });

    updatePercentiles(countyMapData, f => f.properties.averageCharge || 0);

    features = countyMapData.features;
  }
  return fromJS(features);
};

export const getHospitalFeatures = (
  hospitals: Pick<Hospital, "id" | "name" | "latitude" | "longitude">[]
) => {
  // Update data source
  const features = hospitals
    .filter(hospital => hospital.longitude && hospital.latitude)
    .map(hospital => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [hospital.longitude, hospital.latitude]
      },
      properties: {
        name: hospital.name,
        id: hospital.id
      }
    }));
  return fromJS(features);
};

const mapZoomRadiuses = [
  [3, 2],
  [5, 3],
  [7, 4],
  [9, 5],
  [Number.MAX_SAFE_INTEGER, 6]
];

export const getRadiusFromZoom = (zoom: number) => {
  const zoomRadius = mapZoomRadiuses.find(([z]) => zoom < z);
  if (zoomRadius) {
    return zoomRadius[1];
  }
  return mapZoomRadiuses[mapZoomRadiuses.length - 1][1];
};
