import React, { Component } from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme
} from "@material-ui/core/styles";
import {
  SortDirectionType,
  SortDirection,
  RowMouseEventHandlerParams
} from "react-virtualized";
import { withRouter, RouteComponentProps } from "react-router";
import { WithWidth, isWidthUp } from "@material-ui/core/withWidth";
import { Hidden, withWidth } from "@material-ui/core";

import HospitalsMap from "./HospitalsMap";
import ChargesTable, {
  averageChargeDataKey,
  cmsRatingDataKey
} from "./ChargesTable";
import { ChargesPathProps, selectAll, BreakdownType } from "../constants";
import { client } from "../App";
import { parseId } from "../utils";
import CptsForCondition from "../containers/Conditions/CptsDialog";
import ToggleBreakdown from "./ToggleBreakdown";
import {
  ChargesQuery,
  ChargesDocument,
  Hospital,
  ChargesQueryVariables
} from "../generated/graphql";

const styles = ({ mixins, breakpoints }: Theme) =>
  createStyles({
    root: {
      [breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
        marginTop: 24, // In-case of added scroll bar
        marginBottom: 8,
        marginRight: 16,
        marginLeft: 16
      },
      [breakpoints.up("lg")]: {
        justifyContent: "space-between",
        marginTop: 24,
        marginBottom: 16,
        marginRight: 32,
        marginLeft: 32
      },
      flex: 1,
      display: "flex"
    },
    toolbar: { ...mixins.toolbar, height: 118 },
    table: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      minHeight: 400,
      width: "100%"
    },
    mapContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 32
    },
    map: { width: 400 }
  });

interface Drg {
  complications: string;
}

export interface SortArgs {
  sortBy: string;
  sortDirection: SortDirectionType;
}

interface State {
  sortBy?: string;
  sortDirection: SortDirectionType;
  hospitalsCharges: Hospital[];
  complicationsList: string[];
  highlightedRowIndex?: number;
}

interface Props
  extends WithStyles<typeof styles>,
    RouteComponentProps<ChargesPathProps>,
    WithWidth {}

class ChargesContent extends Component<Props, State> {
  state: State = {
    sortDirection: SortDirection.DESC,
    hospitalsCharges: [],
    complicationsList: []
  };

  sort = ({ sortBy, sortDirection }: SortArgs) => {
    let { hospitalsCharges } = this.state;
    hospitalsCharges = this.sortHospitals(
      hospitalsCharges,
      sortBy,
      sortDirection
    );

    this.setState({ sortBy, sortDirection, hospitalsCharges });
  };

  findAmountForDataKey = (hospital: Hospital, dataKey: string) => {
    let amount: number | undefined;
    if (dataKey === averageChargeDataKey) {
      amount =
        hospital.charges
          .map(charge => charge.amount)
          .reduce((sum, current) => sum + current) / hospital.charges.length;
    } else {
      const charge = hospital.charges.find(
        charge => (charge.drg && charge.drg.complications) === dataKey
      );
      amount = charge && charge.amount;
    }
    return amount;
  };

  sortHospitals = (
    hospitalsCharges: Hospital[],
    sortBy: string,
    sortDirection: SortDirectionType
  ) => {
    const notReportingAmount =
      sortDirection === SortDirection.DESC
        ? Number.MAX_SAFE_INTEGER
        : Number.MIN_SAFE_INTEGER;
    return hospitalsCharges.sort((hospitalA, hospitalB) => {
      if (sortBy === cmsRatingDataKey) {
        const hospitalARating =
          typeof hospitalA.rating === "number"
            ? hospitalA.rating
            : notReportingAmount;
        const hospitalBRating =
          typeof hospitalB.rating === "number"
            ? hospitalB.rating
            : notReportingAmount;
        return sortDirection === SortDirection.DESC
          ? hospitalARating - hospitalBRating
          : hospitalBRating - hospitalARating;
      } else {
        const chargeA =
          this.findAmountForDataKey(hospitalA, sortBy) || notReportingAmount;
        const chargeB =
          this.findAmountForDataKey(hospitalB, sortBy) || notReportingAmount;

        return sortDirection === SortDirection.DESC
          ? chargeA - chargeB
          : chargeB - chargeA;
      }
    });
  };

  updatehospitalsCharges = async () => {
    const { width, match } = this.props;
    const { id, county, state } = match.params;
    if (!id || !parseId(id)) {
      this.setState({
        hospitalsCharges: [],
        complicationsList: [],
        sortBy: undefined
      });
      return;
    }
    const { data } = await client.query<
      ChargesQuery | undefined,
      ChargesQueryVariables
    >({
      query: ChargesDocument,
      variables: {
        conditionId: parseId(id) as number,
        ...(county && county !== selectAll && { county }),
        ...(state && state !== selectAll && { state })
      }
    });
    const hospitalsCharges = ((data && data.hospitalsCharges) ||
      []) as Hospital[];
    const complicationsList = (
      (data && data.drgs && data.drgs.map((drg: Drg) => drg.complications)) ||
      []
    ).sort();
    const sortBy =
      complicationsList.length > 1 &&
      match.params.breakdown === BreakdownType.breakdown &&
      isWidthUp("md", width)
        ? complicationsList[0]
        : averageChargeDataKey;
    this.setState({
      hospitalsCharges: this.sortHospitals(
        hospitalsCharges,
        sortBy,
        SortDirection.DESC
      ),
      complicationsList,
      sortBy,
      sortDirection: SortDirection.DESC
    });
  };

  handleRowMouseOver = ({ index }: RowMouseEventHandlerParams) => {
    this.setState(prevState =>
      prevState.highlightedRowIndex !== index
        ? { highlightedRowIndex: index }
        : null
    );
  };

  handleRowMouseOut = () => {
    this.setState({ highlightedRowIndex: undefined });
  };

  async componentDidMount() {
    this.updatehospitalsCharges();
  }

  async componentDidUpdate(prevProps: Props) {
    const { width, match } = this.props;
    const { id, county, state, breakdown } = match.params;
    const prevParams = prevProps.match.params;
    if (
      state !== prevParams.state ||
      county !== prevParams.county ||
      id !== prevParams.id
    ) {
      this.updatehospitalsCharges();
    }
    if (breakdown !== prevParams.breakdown || width !== prevProps.width) {
      const { complicationsList } = this.state;
      const sortBy =
        complicationsList.length > 1 &&
        match.params.breakdown === BreakdownType.breakdown &&
        isWidthUp("md", width)
          ? complicationsList[0]
          : averageChargeDataKey;
      this.sort({ sortBy, sortDirection: SortDirection.DESC });
    }
  }

  render() {
    const { width, classes } = this.props;
    const {
      hospitalsCharges,
      complicationsList,
      sortBy,
      sortDirection,
      highlightedRowIndex
    } = this.state;
    return (
      <>
        <div className={classes.toolbar} />
        <div className={classes.root}>
          <div className={classes.table}>
            <ToggleBreakdown
              title={
                <>
                  Includes at least one of the following procedures...
                  <CptsForCondition />
                </>
              }
              hasBreakdownToggle={
                complicationsList.length > 1 && isWidthUp("md", width)
              }
            />
            <ChargesTable
              hospitals={hospitalsCharges}
              complicationsList={complicationsList}
              sort={this.sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleRowMouseOver={this.handleRowMouseOver}
              handleRowMouseOut={this.handleRowMouseOut}
            />
          </div>
          <Hidden mdDown>
            <div className={classes.mapContainer}>
              <HospitalsMap
                classes={{ root: classes.map }}
                hospitals={hospitalsCharges}
                highlightedHospitalId={
                  highlightedRowIndex !== undefined
                    ? hospitalsCharges[highlightedRowIndex].id
                    : undefined
                }
                hasCounties
                hasUpdateLatLong
              />
            </div>
          </Hidden>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withWidth()(withRouter(ChargesContent)));
