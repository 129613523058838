import {
  ChargesPathProps,
  chargesPathPropToDefaultParam,
  ChargesPathProp,
  Page,
  ProvidersPathProps,
  providersPathPropToDefaultParam,
  ProvidersPathProp
} from "./constants";
import { generatePath } from "react-router";
import { HospitalsPathProps } from "./containers/Hospitals";

export const parseId = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }
  const parsed = Number.parseInt(value);
  if (Number.isNaN(parsed)) {
    return undefined;
  }
  return parsed;
};

interface GetChargesPathArgs extends Partial<ChargesPathProps> {
  id: string;
}

export const getChargesPath = ({
  id,
  state,
  county,
  breakdown
}: GetChargesPathArgs) => {
  if (!state) {
    state = chargesPathPropToDefaultParam[ChargesPathProp.state];
  }
  if (!county) {
    county = chargesPathPropToDefaultParam[ChargesPathProp.county];
  }
  if (!breakdown) {
    breakdown = chargesPathPropToDefaultParam[ChargesPathProp.breakdown];
  }
  return generatePath(`/${Page.conditions}/:id/:state/:county/:breakdown`, {
    id,
    state,
    county,
    breakdown
  });
};

interface GetHospitalsPathArgs extends Partial<HospitalsPathProps> {
  id: string;
}

export const getHospitalsPath = ({ id, breakdown }: GetHospitalsPathArgs) => {
  if (!breakdown) {
    breakdown = chargesPathPropToDefaultParam[ChargesPathProp.breakdown];
  }
  return generatePath(`/${Page.hospitals}/:id/:breakdown`, {
    id,
    breakdown
  });
};

export const getProvidersPath = ({
  type,
  state,
  city
}: Partial<ProvidersPathProps>) => {
  if (!type) {
    type = providersPathPropToDefaultParam[ProvidersPathProp.type];
  }
  if (!state) {
    state = providersPathPropToDefaultParam[ProvidersPathProp.state];
  }
  if (!city) {
    city = providersPathPropToDefaultParam[ProvidersPathProp.city];
  }
  return generatePath(`/${Page.providers}/:type/:state/:city`, {
    type,
    state,
    city
  });
};

export const arraysEqual = (
  arr1: (number | null | undefined)[],
  arr2: (number | null | undefined)[]
) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const formatCurrency = (amount: number) =>
  amount
    .toLocaleString(undefined, {
      style: "currency",
      currency: "USD"
    })
    // Remove decimal with .slice(0, -3)
    .slice(0, -3);
