import React, { Component } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";

import CostVsQualityChartTooltip from "./CostVsQualityChartTooltip";

const styles = createStyles({
  root: {
    flex: 1,
    position: "relative",
    minHeight: 300
  },
  responsiveContainer: {
    position: "absolute"
  }
});

interface State {
  costVsQualityJson: object[] | undefined;
}

class CostVsQualityChart extends Component<WithStyles<typeof styles>, State> {
  state: State = {
    costVsQualityJson: undefined
  };
  controller = new AbortController();

  async componentDidMount() {
    const costVsQualityResult = await fetch(
      `${process.env.PUBLIC_URL}/data/hospital_z_scores.json`,
      { signal: this.controller.signal }
    );
    const costVsQualityJson = await costVsQualityResult.json();
    this.setState({ costVsQualityJson });
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  render() {
    const { classes } = this.props;
    const { costVsQualityJson } = this.state;
    if (!costVsQualityJson) {
      return null;
    }
    return (
      <div className={classes.root}>
        <ResponsiveContainer
          width="100%"
          height="100%"
          className={classes.responsiveContainer}
        >
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20
            }}
          >
            <CartesianGrid />
            <XAxis type="number" dataKey="z" name="Hospital Cost Rating">
              <Label
                value="Cost (lower is cheaper, 0 is average)"
                offset={-8}
                position="insideBottom"
              />
            </XAxis>
            <YAxis type="number" dataKey="r" name="CMS Rating">
              <Label value="Quality" offset={-12} position="insideLeft" />
            </YAxis>
            <Tooltip
              cursor={{ stroke: orange[700], strokeWidth: 2 }}
              content={<CostVsQualityChartTooltip />}
            />
            <Scatter
              name="A Hospital"
              data={costVsQualityJson}
              fill={grey[700]}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default withStyles(styles)(CostVsQualityChart);
