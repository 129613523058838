import React, { Component } from "react";
import MapGL, { NavigationControl, ViewState, ExtraState } from "react-map-gl";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import ReactGA from "react-ga";
import WebMercatorViewport from "viewport-mercator-project";
import { fromJS, List } from "immutable";
import { grey, orange } from "@material-ui/core/colors";
import { withRouter, RouteComponentProps } from "react-router";
import "mapbox-gl/dist/mapbox-gl.css";

import rawMapStyle from "./map-style-basic-v8.json";
import CountyTooltip from "./CountyTooltip";
import { getHospitalsPath, arraysEqual } from "../../utils";
import {
  getHospitalFeatures,
  getRadiusFromZoom,
  mapStops,
  getCountyFeatures,
  CountyHospital
} from "./utils";
import HospitalTooltip from "./HospitalTooltip";
import { Hospital } from "../../generated/graphql.jsx";

const styles = createStyles({
  root: {
    flex: 1,
    position: "relative"
  },
  controlPanel: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 10
  }
});

const countyLayerId = "county";
const countyLayer = fromJS({
  id: countyLayerId,
  source: "hospitals",
  type: "fill",
  interactive: true,
  paint: {
    "fill-color": {
      property: "percentile",
      stops: mapStops
    },
    "fill-opacity": 0.5
  },
  filter: ["==", "$type", "Polygon"]
});

const hospitalLayerId = "hospital";
const hospitalLayer = fromJS({
  id: hospitalLayerId,
  type: "circle",
  source: "hospitals",
  paint: {
    "circle-radius": 3,
    "circle-color": grey[700]
  },
  filter: ["==", "$type", "Point"]
});

const highlightedPointLayerId = "highlighted-hospital";
const highlightedHospitalLayer = fromJS({
  id: highlightedPointLayerId,
  type: "circle",
  source: "hospitals",
  paint: {
    "circle-radius": 8,
    "circle-color": orange[700]
  },
  filter: ["==", "id", ""]
});

export interface HospitalProperties {
  id: number;
  name: string;
}

export interface CountyProperties {
  value?: number;
  percentile?: number;
  averageCharge?: number;
  county: string;
  state: string;
}

export interface Feature<T> {
  properties: T;
  layer?: { id: string };
}

export interface FeatureCollection<T> {
  features: Feature<T>[];
}

const defaultMapStyle = fromJS(rawMapStyle)
  .setIn(
    ["sources", "hospitals"],
    fromJS({
      type: "geojson",
      data: { type: "FeatureCollection", features: [] }
    })
  )
  .updateIn(["layers"], (list: List<any>) =>
    list
      .push(countyLayer)
      .push(hospitalLayer)
      .push(highlightedHospitalLayer)
  );

const mapboxApiAccessToken =
  "pk.eyJ1IjoidGlhZ29iIiwiYSI6ImNqcmhhc3kwdzA0bnI0M3A4aHgwaWdmOWIifQ.RdcDFR2sdQtqqvwRFwi8NA";

const allUsViewport = {
  latitude: 37.0902,
  longitude: -95.7129,
  zoom: 2
};

interface SrcEvent {
  offsetX: number;
  offsetY: number;
}

interface Event {
  features: Feature<CountyProperties | HospitalProperties>[];
  srcEvent: SrcEvent;
}

// RouteComponentProps genreric is blank because we only need history
interface Props extends WithStyles<typeof styles>, RouteComponentProps<{}> {
  hospitals: Pick<Hospital, "id" | "name" | "latitude" | "longitude">[];
  highlightedHospitalId?: number | null;
  hasCounties?: boolean;
  hasUpdateLatLong?: boolean;
}

interface State {
  viewport: ViewState;
  mapStyle: any;
  usCountiesGeoJson?: FeatureCollection<CountyProperties>;
  hoveredFeature?: Feature<CountyProperties | HospitalProperties>;
  x?: number;
  y?: number;
}

class HospitalsMap extends Component<Props, State> {
  state: State = {
    viewport: allUsViewport,
    mapStyle: defaultMapStyle
  };
  controller = new AbortController();

  updateViewport = (viewport: ViewState) => {
    ReactGA.event({
      category: "Map",
      action: "Update Viewport"
    });
    this.setState(prevState => {
      let mapStyle = prevState.mapStyle;
      if (prevState.viewport.zoom !== viewport.zoom) {
        mapStyle = mapStyle.setIn(
          [
            "layers",
            mapStyle.get("layers").count() - 2,
            "paint",
            "circle-radius"
          ],
          getRadiusFromZoom(viewport.zoom)
        );
      }
      return { viewport: { ...viewport }, ...(mapStyle && { mapStyle }) };
    });
  };

  updateLatLong = () => {
    const { hospitals, hasUpdateLatLong } = this.props;
    if (!hasUpdateLatLong) {
      return;
    }
    const lats = hospitals.map(hospital => hospital.latitude);
    const lngs = hospitals.map(hospital => hospital.longitude);
    let viewport;
    if (lats.length > 1 && lngs.length > 1) {
      const minLat = Math.min(...lats);
      const maxLat = Math.max(...lats);
      const minLng = Math.min(...lngs);
      const maxLng = Math.max(...lngs);
      viewport = new WebMercatorViewport(this.state.viewport);
      viewport = viewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
        padding: 64
      });
    } else if (lats.length === 1 && lngs.length === 1) {
      viewport = {
        ...this.state.viewport,
        latitude: lats[0],
        longitude: lngs[0],
        zoom: 10
      };
    }
    if (viewport) {
      this.updateViewport(viewport);
    }
  };

  async componentDidMount() {
    const { hasCounties } = this.props;
    let usCountiesGeoJson;
    if (hasCounties) {
      const countiesResult = await fetch(
        `${process.env.PUBLIC_URL}/data/us_counties.json`,
        { signal: this.controller.signal }
      );
      usCountiesGeoJson = await countiesResult.json();
    }
    this.setState({ usCountiesGeoJson }, this.updateFeatures);
    this.updateHighlightedHospital();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  updateFeatures() {
    this.updateLatLong();
    const { hospitals, hasCounties } = this.props;
    this.setState(prevState => {
      let { mapStyle, usCountiesGeoJson } = prevState;
      let features: List<
        Feature<CountyProperties | HospitalProperties>
      > = List();
      if (usCountiesGeoJson && hasCounties) {
        features = features.push(
          ...getCountyFeatures(usCountiesGeoJson, hospitals as CountyHospital[])
        );
      }
      features = features.push(...getHospitalFeatures(hospitals));

      mapStyle = mapStyle.setIn(
        ["sources", "hospitals", "data", "features"],
        features
      );

      return { mapStyle };
    });
  }

  updateHighlightedHospital = () => {
    const { highlightedHospitalId } = this.props;
    this.setState(prevState => {
      let mapStyle = prevState.mapStyle;
      mapStyle = mapStyle.setIn(
        ["layers", mapStyle.get("layers").count() - 1, "filter", 2],
        highlightedHospitalId === undefined ? "" : highlightedHospitalId
      );
      return { mapStyle };
    });
  };

  async componentDidUpdate(prevProps: Props) {
    const { hospitals, highlightedHospitalId } = this.props;
    if (
      !arraysEqual(
        hospitals.map(hospital => hospital.id),
        prevProps.hospitals.map(hospital => hospital.id)
      )
    ) {
      this.updateFeatures();
    }
    if (highlightedHospitalId !== prevProps.highlightedHospitalId) {
      this.updateHighlightedHospital();
    }
  }

  handleHover = (event: Event) => {
    const {
      features,
      srcEvent: { offsetX, offsetY }
    } = event;
    const { highlightedHospitalId } = this.props;
    this.setState(prevState => {
      const hospitalFeature =
        features &&
        (features.find(f =>
          f.layer ? f.layer.id === hospitalLayerId : false
        ) as Feature<HospitalProperties> | undefined);
      const countyFeature =
        features &&
        (features.find(f =>
          f.layer ? f.layer.id === countyLayerId : false
        ) as Feature<CountyProperties> | undefined);
      let mapStyle = prevState.mapStyle;
      if (highlightedHospitalId === undefined) {
        mapStyle = mapStyle.setIn(
          ["layers", mapStyle.get("layers").count() - 1, "filter", 2],
          hospitalFeature ? hospitalFeature.properties.id : ""
        );
      }
      return {
        hoveredFeature: hospitalFeature || countyFeature,
        x: offsetX,
        y: offsetY,
        ...(mapStyle && { mapStyle })
      };
    });
  };

  handleClick = (event: Event) => {
    const { history } = this.props;
    const { features } = event;
    const clickedFeature =
      features &&
      (features.find(f =>
        f.layer ? f.layer.id === hospitalLayerId : false
      ) as Feature<HospitalProperties> | undefined);
    if (clickedFeature && clickedFeature.properties.id) {
      const to = getHospitalsPath({
        id: clickedFeature.properties.id.toString()
      });
      ReactGA.pageview(to);
      history.push(to);
    }
  };

  getCursor = ({ isDragging }: ExtraState) => {
    const { hoveredFeature } = this.state;
    if (
      hoveredFeature &&
      hoveredFeature.layer &&
      hoveredFeature.layer.id === hospitalLayerId
    ) {
      return "pointer";
    }
    return isDragging ? "grabbing" : "grab";
  };

  render() {
    const { hoveredFeature, x, y, viewport, mapStyle } = this.state;
    const { latitude, longitude, zoom } = viewport;
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <MapGL
          style={{ position: "absolute" }}
          width="100%"
          height="100%"
          latitude={latitude}
          longitude={longitude}
          zoom={zoom}
          onViewportChange={this.updateViewport}
          mapboxApiAccessToken={mapboxApiAccessToken}
          mapStyle={mapStyle}
          onClick={this.handleClick}
          interactiveLayerIds={[hospitalLayerId, countyLayerId]}
          onHover={this.handleHover}
          getCursor={this.getCursor}
        >
          {hoveredFeature &&
            hoveredFeature.layer &&
            (hoveredFeature.layer.id === countyLayerId ? (
              <CountyTooltip
                x={x}
                y={y}
                hoveredFeature={hoveredFeature as Feature<CountyProperties>}
              />
            ) : (
              <HospitalTooltip
                x={x}
                y={y}
                hoveredFeature={hoveredFeature as Feature<HospitalProperties>}
              />
            ))}
          <div className={classes.controlPanel}>
            <NavigationControl onViewportChange={this.updateViewport} />
          </div>
        </MapGL>
      </Paper>
    );
  }
}

export default withStyles(styles)(withRouter(HospitalsMap));
