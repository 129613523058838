import React from "react";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { TableCellProps, Index, SortDirectionType } from "react-virtualized";
import { RouteComponentProps, withRouter, match } from "react-router";
import { withWidth } from "@material-ui/core";
import { WithWidth, isWidthUp } from "@material-ui/core/withWidth";

import { SortArgs, HospitalsPathProps, ConditionsCharges, Condition } from ".";
import VirtualizedTable, {
  VirtualizedTableColumn
} from "../../components/VirtualizedTable";
import ComplicationsTooltip from "../../components/ComplicationsTooltip";
import Link from "../../components/Link";
import { BreakdownType } from "../../constants";
import { getChargesPath, formatCurrency } from "../../utils";

export const averageChargeDataKey = BreakdownType.average;

const getCharge = (condition: Condition, complications: string) => {
  const drg = condition.drgs.find(drg => drg.complications === complications);
  if (drg && drg.charges.length > 0) {
    return formatCurrency(drg.charges[0].amount);
  }
  return null;
};

const styles = createStyles({
  root: {
    flex: 1,
    minHeight: 300,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  conditionName: {
    display: "box",
    lineClamp: 3,
    boxOrient: "vertical",
    overflow: "hidden"
  }
});

interface GetColumnsArgs extends WithWidth, WithStyles<typeof styles> {
  match: match<HospitalsPathProps>;
  conditions: ConditionsCharges;
  complicationsList: string[];
}

const getColumns = ({
  width,
  match,
  conditions,
  complicationsList,
  classes
}: GetColumnsArgs) => {
  const columns: VirtualizedTableColumn[] = [
    {
      dataKey: "name",
      label: "CONDITION NAME",
      cellContentRenderer: ({ rowIndex }: TableCellProps) => {
        const condition = conditions[rowIndex];
        return (
          <Link
            to={getChargesPath({ id: condition.id.toString() })}
            className={classes.conditionName}
          >
            {condition.name}
          </Link>
        );
      },
      width: 180,
      flexGrow: 1.0,
      disableSort: true
    }
  ];
  if (
    complicationsList.length > 1 &&
    match.params.breakdown === BreakdownType.breakdown &&
    isWidthUp("md", width)
  ) {
    columns.push(
      ...complicationsList.map(complications => ({
        dataKey: complications,
        label: <ComplicationsTooltip complications={complications} />,
        cellContentRenderer: ({ rowIndex }: TableCellProps) =>
          getCharge(conditions[rowIndex], complications),
        width: 140,
        disableSort: !conditions.length
      }))
    );
  } else {
    columns.push({
      dataKey: averageChargeDataKey,
      label: "AVG CHARGE",
      cellContentRenderer: ({ rowIndex }: TableCellProps) => {
        const condition = conditions[rowIndex];
        return condition.drgs.length > 0 && condition.drgs[0].charges.length > 0
          ? formatCurrency(
              condition.drgs
                .map(drg => drg.charges[0].amount)
                .reduce((sum, current) => sum + current) / condition.drgs.length
            )
          : null;
      },
      width: 180,
      disableSort: !conditions.length
    });
  }
  return columns;
};

interface Props
  extends WithStyles<typeof styles>,
    RouteComponentProps<HospitalsPathProps>,
    WithWidth {
  conditions: Condition[];
  complicationsList: string[];
  sort?: (info: SortArgs) => void;
  sortBy?: string;
  sortDirection?: SortDirectionType;
}

const ChargesTable = ({
  width,
  match,
  complicationsList,
  conditions,
  sort,
  sortBy,
  sortDirection,
  classes
}: Props) => (
  <Paper className={classes.root}>
    <VirtualizedTable
      rowCount={conditions.length}
      rowGetter={({ index }: Index) => conditions[index]}
      columns={getColumns({
        width,
        match,
        conditions,
        complicationsList,
        classes
      })}
      rowHeight={56}
      headerHeight={56}
      sort={sort}
      sortBy={sortBy}
      sortDirection={sortDirection}
    />
  </Paper>
);

export default withStyles(styles)(withWidth()(withRouter(ChargesTable)));
