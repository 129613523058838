import { createMuiTheme } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";

export enum ChargePage {
  conditions = "conditions",
  codes = "codes"
}

export enum Page {
  conditions = "conditions",
  codes = "codes",
  categories = "categories",
  hospitals = "hospitals",
  search = "search",
  about = "about",
  providers = "referrals/providers"
}

export const searchTypes = [Page.conditions, Page.hospitals];

export enum ConditionType {
  medical = "medical",
  surgical = "surgical"
}

export const conditionTypeToName = {
  [ConditionType.medical]: "Diagnosis",
  [ConditionType.surgical]: "Surgery"
};

export enum ChargesPathProp {
  id = "id",
  state = "state",
  county = "county",
  breakdown = "breakdown"
}

export enum BreakdownType {
  average = "avg",
  breakdown = "brk"
}

export type ChargesPathProps = { [pathProp in ChargesPathProp]: string };

const selectAny = "any";
export const selectAll = "all";

export const chargesPathPropToDefaultValue = {
  [ChargesPathProp.id]: undefined,
  [ChargesPathProp.county]: selectAll,
  [ChargesPathProp.state]: selectAll,
  [ChargesPathProp.breakdown]: undefined
};

export const chargesPathPropToDefaultParam = {
  [ChargesPathProp.id]: selectAny,
  [ChargesPathProp.county]: selectAll,
  [ChargesPathProp.state]: selectAll,
  [ChargesPathProp.breakdown]: BreakdownType.average
};

export enum ProvidersPathProp {
  type = "type",
  city = "city",
  state = "state"
}

export type ProvidersPathProps = { [pathProp in ProvidersPathProp]: string };

export const providersPathPropToDefaultValue = {
  [ProvidersPathProp.type]: selectAll,
  [ProvidersPathProp.city]: undefined,
  [ProvidersPathProp.state]: undefined
};

export const providersPathPropToDefaultParam = {
  [ProvidersPathProp.type]: selectAll,
  [ProvidersPathProp.city]: selectAny,
  [ProvidersPathProp.state]: selectAny
};

export const theme = createMuiTheme({
  palette: {
    secondary: { main: orange[700] }
  },
  typography: {
    useNextVariants: true
  }
});
