import React from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
  Tabs,
  Tab,
  Typography
} from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router";
import ReactGA, { OutboundLink } from "react-ga";

import { Page, searchTypes } from "../../constants";
import SearchConditions from "./SearchConditions";
import SearchHospitals from "./SearchHospitals";
import Header from "../../components/Header";
import Link from "../../components/Link";
import { getChargesPath } from "../../utils";
import HospitalsMap from "../../components/HospitalsMap";
import CostVsQualityStory from "./CostVsQuality/CostVsQualityStory";
import { HospitalsComponent } from "../../generated/graphql";

export enum SearchPathProp {
  type = "type"
}

export type SearchPathProps = { [pathProp in SearchPathProp]: Page };

const styles = ({ mixins, breakpoints }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1
    },
    contentClass: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      [breakpoints.down("sm")]: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 16,
        paddingLeft: 16
      },
      [breakpoints.up("md")]: {
        paddingTop: 32,
        paddingBottom: 32,
        paddingRight: 64,
        paddingLeft: 64
      }
    },
    toolbar: { ...mixins.toolbar, height: 118 },
    aboutText: {
      marginTop: 32
    },
    emailText: {
      marginBottom: 32
    }
  });

const Search = ({
  history,
  match,
  classes
}: WithStyles<typeof styles, true> & RouteComponentProps<SearchPathProps>) => (
  <>
    <Header>
      <Tabs
        value={searchTypes.indexOf(match.params.type)}
        onChange={(_: any, value: number) => {
          const searchType = searchTypes[value];
          const to = `/${Page.search}/${searchType}`;
          ReactGA.pageview(to);
          history.push(to);
        }}
      >
        {searchTypes.map((searchType, index) => (
          <Tab label={searchType.toUpperCase()} key={index} />
        ))}
      </Tabs>
    </Header>

    <div className={classes.contentClass}>
      <div className={classes.toolbar} />
      {match.params.type === Page.conditions ? (
        <SearchConditions />
      ) : (
        <SearchHospitals />
      )}
      <Typography variant="body1" gutterBottom className={classes.aboutText}>
        Compare Hospitals makes information about the price of hospital
        treatments available to everyone. See what hospitals in your area charge
        on average for procedures like{" "}
        <Link to={getChargesPath({ id: "1" })}>Heart Transplants</Link> or{" "}
        <Link to={getChargesPath({ id: "264" })}>Cesarean Section</Link>. Or,
        browse by diagnosis, such as{" "}
        <Link to={getChargesPath({ id: "308" })}>Septicemia</Link>,{" "}
        <Link to={getChargesPath({ id: "237" })}>Kidney Stones</Link>, or{" "}
        <Link to={getChargesPath({ id: "36" })}>Concussions</Link>.
      </Typography>

      <Typography variant="body1" className={classes.emailText}>
        Email us with feedback, questions or concerns{" "}
        <Link
          color="secondary"
          component={(props: any) => (
            <OutboundLink
              {...props}
              eventLabel="email"
              to="mailto:info@comparehospitals.org"
            />
          )}
          style={{ cursor: "pointer" }}
        >
          info@comparehospitals.org
        </Link>
        .
      </Typography>

      {match.params.type === Page.hospitals ? (
        <HospitalsComponent>
          {({ data }) => (
            <HospitalsMap
              hospitals={data && data.hospitals ? data.hospitals : []}
            />
          )}
        </HospitalsComponent>
      ) : (
        <CostVsQualityStory />
      )}
    </div>
  </>
);

export default withStyles(styles)(withRouter(Search));
