import React from "react";
import classNames from "classnames";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  AutoSizer,
  Column,
  SortDirection,
  SortDirectionType,
  Table,
  TableCellRenderer,
  TableCellProps,
  TableHeaderProps,
  TableProps,
  ColumnProps,
  Index
} from "react-virtualized";

const styles = ({ typography, palette, breakpoints }: Theme) =>
  createStyles({
    table: {
      fontFamily: typography.fontFamily
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box"
    },
    tableRow: {}, // Can be overridden by parent
    tableRowHover: {
      "&:hover": {
        backgroundColor: palette.action.hover
      }
    },
    tableCell: {
      flex: 1,
      [breakpoints.down("xs")]: {
        paddingLeft: 8,
        "&:last-child": {
          paddingRight: 8
        }
      }
    },
    noClick: {
      cursor: "initial"
    }
  });

export interface VirtualizedTableColumn extends ColumnProps {
  cellContentRenderer?: TableCellRenderer;
  numeric?: boolean;
}

interface Props extends TableProps, WithStyles<typeof styles> {
  columns: VirtualizedTableColumn[];
  rowHeight: number | ((info: Index) => number);
  headerHeight: number;
  tableRef?: React.RefObject<Table>;
}

interface HeaderRendererArgs extends TableHeaderProps {
  columnIndex: number;
}

class MuiVirtualizedTable extends React.PureComponent<Props, {}> {
  getRowClassName = ({ index }: Index) => {
    const { classes, rowClassName, onRowClick } = this.props;

    return classNames(
      classes.tableRow,
      classes.flexContainer,
      typeof rowClassName === "function"
        ? rowClassName({ index })
        : rowClassName,
      {
        [classes.tableRowHover]: index !== -1 && onRowClick !== null
      }
    );
  };

  getCellClassName = ({ index }: Index) => {
    const { classes, onRowClick, cellClassName } = this.props;

    return classNames(
      classes.tableCell,
      classes.flexContainer,
      typeof cellClassName === "function"
        ? cellClassName({ index })
        : cellClassName,
      {
        [classes.noClick]: onRowClick === null
      }
    );
  };

  cellRenderer = ({ cellData, columnIndex, rowIndex }: TableCellProps) => {
    const { columns, rowHeight } = this.props;
    return (
      <TableCell
        component="div"
        variant="body"
        className={this.getCellClassName({ index: rowIndex })}
        style={{
          height:
            typeof rowHeight === "function"
              ? rowHeight({ index: columnIndex })
              : rowHeight
        }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({
    label,
    columnIndex,
    dataKey,
    sortBy,
    sortDirection
  }: HeaderRendererArgs) => {
    const { headerHeight, columns, classes, sort } = this.props;
    const direction = new Map<SortDirectionType, "asc" | "desc">([
      [SortDirection.ASC, "asc"],
      [SortDirection.DESC, "desc"]
    ]);

    const inner =
      !columns[columnIndex].disableSort &&
      sort != null &&
      sortDirection &&
      direction.has(sortDirection) ? (
        <TableSortLabel
          active={dataKey === sortBy}
          direction={direction.get(sortDirection)}
        >
          {label}
        </TableSortLabel>
      ) : (
        label
      );

    return (
      <TableCell
        component="div"
        className={classNames(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowCount, tableRef, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            ref={tableRef}
            className={classes.table}
            height={height}
            width={width}
            rowCount={rowCount}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(
              (
                { cellContentRenderer = null, className, dataKey, ...other },
                index
              ) => {
                let renderer;
                if (cellContentRenderer !== null) {
                  renderer = (cellRendererProps: TableCellProps) =>
                    this.cellRenderer({
                      ...cellRendererProps,
                      cellData: cellContentRenderer(cellRendererProps)
                    });
                } else {
                  renderer = this.cellRenderer;
                }

                return (
                  <Column
                    key={dataKey}
                    headerRenderer={headerProps =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index
                      })
                    }
                    className={classNames(classes.flexContainer, className)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              }
            )}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

export default withStyles(styles)(MuiVirtualizedTable);
