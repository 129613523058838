import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";

import {
  ChargesPathProps,
  ChargesPathProp,
  chargesPathPropToDefaultValue
} from "../constants";
import { SelectOption } from "./SelectUrlParam";
import { CountiesComponent, CountiesQuery } from "../generated/graphql";
import SelectChargesParam from "./SelectChargesParam";

const styles = createStyles({
  select: {
    flex: "0 0 auto",
    paddingLeft: 8,
    paddingRight: 16,
    width: 220
  }
});

export const allCounties: Readonly<SelectOption> = {
  label: "All Counties",
  value: chargesPathPropToDefaultValue[ChargesPathProp.county]
};

const getCountyOptions = (data: CountiesQuery | undefined) => {
  let countyOptions: SelectOption[] = [];
  if (data && data.counties) {
    countyOptions = data.counties.map(county => {
      return {
        value: county,
        label: county
      };
    });
  }
  countyOptions.unshift(allCounties);
  return countyOptions;
};

const SelectCounty = ({
  match,
  classes
}: WithStyles<typeof styles> & RouteComponentProps<ChargesPathProps>) => (
  <CountiesComponent
    skip={!match.params.state}
    variables={{ state: match.params.state }}
  >
    {({ data }) => (
      <SelectChargesParam
        className={classes.select}
        options={getCountyOptions(data)}
        pathProp={ChargesPathProp.county}
      />
    )}
  </CountiesComponent>
);

export default withStyles(styles)(withRouter(SelectCounty));
