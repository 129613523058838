import React from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  IconButton,
  Hidden,
  withStyles
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import Header from "../../components/Header";

const styles = ({ zIndex }: Theme) =>
  createStyles({
    appBar: {
      zIndex: zIndex.drawer + 1
    },
    menuButton: {
      marginRight: 20
    }
  });

interface Props extends WithStyles<typeof styles> {
  handleDrawerToggle: () => void;
}

const CategoriesHeader = ({ handleDrawerToggle, classes }: Props) => (
  <Header
    classes={{ root: classes.appBar }}
    ToolbarIcon={
      <Hidden mdUp>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    }
  />
);

export default withStyles(styles)(CategoriesHeader);
