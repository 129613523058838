import React, { Component } from "react";
import { ValueType } from "react-select/lib/types";
import { withRouter, RouteComponentProps, generatePath } from "react-router";
import { Props as ReactSelectProps } from "react-select/lib/Creatable";
import ReactGA from "react-ga";

import { Page } from "../../constants";
import { client } from "../../App";
import { SearchPathProps } from ".";
import SearchListItem from "./SearchListItem";
import SearchBar, { SelectOption, OptGroup } from "./SearchBar";
import { getChargesPath } from "../../utils";
import {
  SearchConditionCategoriesDocument,
  SearchConditionsDocument,
  SearchConditionCategoriesQuery,
  SearchConditionsQuery,
  SearchConditionsQueryVariables
} from "../../generated/graphql";

const getContditionsOptions = (data: SearchConditionsQuery | undefined) => {
  if (!data || !data.searchConditions) {
    return [];
  }
  const categoryToConditions = new Map<string, SelectOption[]>();
  for (const condition of data.searchConditions) {
    const conditions = categoryToConditions.get(
      condition.conditionCategory.name
    );
    const option = {
      label: (
        <SearchListItem
          primary={condition.name}
          secondary={condition.cpts.length ? condition.cpts[0].name : undefined}
        />
      ),
      value: getChargesPath({
        id: condition.id.toString()
      })
    };
    if (conditions) {
      conditions.push(option);
    } else {
      categoryToConditions.set(condition.conditionCategory.name, [option]);
    }
  }
  return [...categoryToConditions.entries()].map(([key, value]) => ({
    label: key,
    options: value
  }));
};

const getCategoriesOptions = (
  data: SearchConditionCategoriesQuery | undefined
) =>
  data
    ? data.searchConditionCategories
      ? [
          {
            label: "Categories",
            options: data.searchConditionCategories.map(category => ({
              label: <SearchListItem primary={category.name} />,
              value: generatePath(`/${Page.categories}/:id`, {
                id: category.id
              })
            }))
          }
        ]
      : []
    : [];

interface State {
  categoriesOptions: OptGroup[];
}

class SearchConditions extends Component<
  ReactSelectProps<SelectOption> & RouteComponentProps<SearchPathProps>,
  State
> {
  state: State = { categoriesOptions: [] };

  async componentDidMount() {
    const { data } = await client.query<
      SearchConditionCategoriesQuery | undefined
    >({
      query: SearchConditionCategoriesDocument
    });
    this.setState({ categoriesOptions: getCategoriesOptions(data) });
  }

  render() {
    const { categoriesOptions } = this.state;
    const { history } = this.props;
    return (
      <SearchBar
        placeholder="Search conditions..."
        defaultOptions={categoriesOptions}
        loadOptions={async (searchText: string) => {
          const { data } = await client.query<
            SearchConditionsQuery | undefined,
            SearchConditionsQueryVariables
          >({
            query: SearchConditionsDocument,
            variables: { searchText }
          });
          return getContditionsOptions(data);
        }}
        onChange={(selectOption: ValueType<SelectOption | OptGroup>) => {
          if (
            !selectOption ||
            Array.isArray(selectOption) ||
            !("value" in selectOption)
          ) {
            return;
          }
          ReactGA.pageview(selectOption.value);
          history.push(selectOption.value);
        }}
      />
    );
  }
}

export default withRouter(SearchConditions);
