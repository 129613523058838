import gql from "graphql-tag";
import * as React from "react";
import * as ReactApollo from "react-apollo";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const ChargesDocument = gql`
  query Charges($conditionId: Int!, $county: String, $state: String) {
    drgs(conditionId: $conditionId) {
      complications
    }
    hospitalsCharges(
      conditionId: $conditionId
      county: $county
      state: $state
    ) {
      id
      name
      latitude
      longitude
      county
      state
      rating
      charges {
        amount
        drg {
          complications
        }
      }
    }
  }
`;

export const ChargesComponent = (
  props: Omit<
    Omit<ReactApollo.QueryProps<ChargesQuery, ChargesQueryVariables>, "query">,
    "variables"
  > & { variables: ChargesQueryVariables }
) => (
  <ReactApollo.Query<ChargesQuery, ChargesQueryVariables>
    query={ChargesDocument}
    {...props}
  />
);

export type ChargesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<ChargesQuery, ChargesQueryVariables>
> &
  TChildProps;
export function withCharges<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ChargesQuery,
    ChargesQueryVariables,
    ChargesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ChargesQuery,
    ChargesQueryVariables,
    ChargesProps<TChildProps>
  >(ChargesDocument, {
    alias: "withCharges",
    ...operationOptions
  });
}
export const CountiesDocument = gql`
  query Counties($state: String!) {
    counties(state: $state)
  }
`;

export const CountiesComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<CountiesQuery, CountiesQueryVariables>,
      "query"
    >,
    "variables"
  > & { variables: CountiesQueryVariables }
) => (
  <ReactApollo.Query<CountiesQuery, CountiesQueryVariables>
    query={CountiesDocument}
    {...props}
  />
);

export type CountiesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<CountiesQuery, CountiesQueryVariables>
> &
  TChildProps;
export function withCounties<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CountiesQuery,
    CountiesQueryVariables,
    CountiesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    CountiesQuery,
    CountiesQueryVariables,
    CountiesProps<TChildProps>
  >(CountiesDocument, {
    alias: "withCounties",
    ...operationOptions
  });
}
export const StatesDocument = gql`
  query States {
    states
  }
`;

export const StatesComponent = (
  props: Omit<
    Omit<ReactApollo.QueryProps<StatesQuery, StatesQueryVariables>, "query">,
    "variables"
  > & { variables?: StatesQueryVariables }
) => (
  <ReactApollo.Query<StatesQuery, StatesQueryVariables>
    query={StatesDocument}
    {...props}
  />
);

export type StatesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<StatesQuery, StatesQueryVariables>
> &
  TChildProps;
export function withStates<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    StatesQuery,
    StatesQueryVariables,
    StatesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    StatesQuery,
    StatesQueryVariables,
    StatesProps<TChildProps>
  >(StatesDocument, {
    alias: "withStates",
    ...operationOptions
  });
}
export const AllSearchConditionCategoriesDocument = gql`
  query AllSearchConditionCategories {
    searchConditionCategories {
      name
      id
    }
  }
`;

export const AllSearchConditionCategoriesComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        AllSearchConditionCategoriesQuery,
        AllSearchConditionCategoriesQueryVariables
      >,
      "query"
    >,
    "variables"
  > & { variables?: AllSearchConditionCategoriesQueryVariables }
) => (
  <ReactApollo.Query<
    AllSearchConditionCategoriesQuery,
    AllSearchConditionCategoriesQueryVariables
  >
    query={AllSearchConditionCategoriesDocument}
    {...props}
  />
);

export type AllSearchConditionCategoriesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    AllSearchConditionCategoriesQuery,
    AllSearchConditionCategoriesQueryVariables
  >
> &
  TChildProps;
export function withAllSearchConditionCategories<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    AllSearchConditionCategoriesQuery,
    AllSearchConditionCategoriesQueryVariables,
    AllSearchConditionCategoriesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    AllSearchConditionCategoriesQuery,
    AllSearchConditionCategoriesQueryVariables,
    AllSearchConditionCategoriesProps<TChildProps>
  >(AllSearchConditionCategoriesDocument, {
    alias: "withAllSearchConditionCategories",
    ...operationOptions
  });
}
export const ConditionCategoryDocument = gql`
  query ConditionCategory($id: Int!) {
    conditionCategory(id: $id) {
      name
    }
  }
`;

export const ConditionCategoryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        ConditionCategoryQuery,
        ConditionCategoryQueryVariables
      >,
      "query"
    >,
    "variables"
  > & { variables: ConditionCategoryQueryVariables }
) => (
  <ReactApollo.Query<ConditionCategoryQuery, ConditionCategoryQueryVariables>
    query={ConditionCategoryDocument}
    {...props}
  />
);

export type ConditionCategoryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<ConditionCategoryQuery, ConditionCategoryQueryVariables>
> &
  TChildProps;
export function withConditionCategory<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ConditionCategoryQuery,
    ConditionCategoryQueryVariables,
    ConditionCategoryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ConditionCategoryQuery,
    ConditionCategoryQueryVariables,
    ConditionCategoryProps<TChildProps>
  >(ConditionCategoryDocument, {
    alias: "withConditionCategory",
    ...operationOptions
  });
}
export const ConditionsDocument = gql`
  query Conditions($id: Int!) {
    conditions(conditionCategoryId: $id) {
      name
      type
      id
    }
  }
`;

export const ConditionsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<ConditionsQuery, ConditionsQueryVariables>,
      "query"
    >,
    "variables"
  > & { variables: ConditionsQueryVariables }
) => (
  <ReactApollo.Query<ConditionsQuery, ConditionsQueryVariables>
    query={ConditionsDocument}
    {...props}
  />
);

export type ConditionsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<ConditionsQuery, ConditionsQueryVariables>
> &
  TChildProps;
export function withConditions<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ConditionsQuery,
    ConditionsQueryVariables,
    ConditionsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ConditionsQuery,
    ConditionsQueryVariables,
    ConditionsProps<TChildProps>
  >(ConditionsDocument, {
    alias: "withConditions",
    ...operationOptions
  });
}
export const ConditionDocument = gql`
  query Condition($id: Int!) {
    condition(id: $id) {
      name
      cpts {
        name
      }
    }
  }
`;

export const ConditionComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<ConditionQuery, ConditionQueryVariables>,
      "query"
    >,
    "variables"
  > & { variables: ConditionQueryVariables }
) => (
  <ReactApollo.Query<ConditionQuery, ConditionQueryVariables>
    query={ConditionDocument}
    {...props}
  />
);

export type ConditionProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<ConditionQuery, ConditionQueryVariables>
> &
  TChildProps;
export function withCondition<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ConditionQuery,
    ConditionQueryVariables,
    ConditionProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ConditionQuery,
    ConditionQueryVariables,
    ConditionProps<TChildProps>
  >(ConditionDocument, {
    alias: "withCondition",
    ...operationOptions
  });
}
export const AllConditionsDocument = gql`
  query AllConditions {
    conditions {
      name
      type
      id
    }
  }
`;

export const AllConditionsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<AllConditionsQuery, AllConditionsQueryVariables>,
      "query"
    >,
    "variables"
  > & { variables?: AllConditionsQueryVariables }
) => (
  <ReactApollo.Query<AllConditionsQuery, AllConditionsQueryVariables>
    query={AllConditionsDocument}
    {...props}
  />
);

export type AllConditionsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<AllConditionsQuery, AllConditionsQueryVariables>
> &
  TChildProps;
export function withAllConditions<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    AllConditionsQuery,
    AllConditionsQueryVariables,
    AllConditionsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    AllConditionsQuery,
    AllConditionsQueryVariables,
    AllConditionsProps<TChildProps>
  >(AllConditionsDocument, {
    alias: "withAllConditions",
    ...operationOptions
  });
}
export const ConditionsChargesDocument = gql`
  query ConditionsCharges($hospitalId: Int!) {
    hospital(id: $hospitalId) {
      id
      name
      latitude
      longitude
      address
      city
      state
      zipCode
      phoneNumber
      rating
      ratingNotes
      inSet
      hasCdm
    }
    conditionsCharges(hospitalId: $hospitalId) {
      id
      name
      drgs {
        complications
        charges {
          amount
        }
      }
    }
  }
`;

export const ConditionsChargesComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        ConditionsChargesQuery,
        ConditionsChargesQueryVariables
      >,
      "query"
    >,
    "variables"
  > & { variables: ConditionsChargesQueryVariables }
) => (
  <ReactApollo.Query<ConditionsChargesQuery, ConditionsChargesQueryVariables>
    query={ConditionsChargesDocument}
    {...props}
  />
);

export type ConditionsChargesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<ConditionsChargesQuery, ConditionsChargesQueryVariables>
> &
  TChildProps;
export function withConditionsCharges<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ConditionsChargesQuery,
    ConditionsChargesQueryVariables,
    ConditionsChargesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ConditionsChargesQuery,
    ConditionsChargesQueryVariables,
    ConditionsChargesProps<TChildProps>
  >(ConditionsChargesDocument, {
    alias: "withConditionsCharges",
    ...operationOptions
  });
}
export const HospitalsDocument = gql`
  query Hospitals {
    hospitals {
      id
      name
      latitude
      longitude
    }
  }
`;

export const HospitalsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<HospitalsQuery, HospitalsQueryVariables>,
      "query"
    >,
    "variables"
  > & { variables?: HospitalsQueryVariables }
) => (
  <ReactApollo.Query<HospitalsQuery, HospitalsQueryVariables>
    query={HospitalsDocument}
    {...props}
  />
);

export type HospitalsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<HospitalsQuery, HospitalsQueryVariables>
> &
  TChildProps;
export function withHospitals<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    HospitalsQuery,
    HospitalsQueryVariables,
    HospitalsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    HospitalsQuery,
    HospitalsQueryVariables,
    HospitalsProps<TChildProps>
  >(HospitalsDocument, {
    alias: "withHospitals",
    ...operationOptions
  });
}
export const SearchConditionCategoriesDocument = gql`
  query SearchConditionCategories($searchText: String) {
    searchConditionCategories(name: $searchText) {
      name
      id
    }
  }
`;

export const SearchConditionCategoriesComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        SearchConditionCategoriesQuery,
        SearchConditionCategoriesQueryVariables
      >,
      "query"
    >,
    "variables"
  > & { variables?: SearchConditionCategoriesQueryVariables }
) => (
  <ReactApollo.Query<
    SearchConditionCategoriesQuery,
    SearchConditionCategoriesQueryVariables
  >
    query={SearchConditionCategoriesDocument}
    {...props}
  />
);

export type SearchConditionCategoriesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    SearchConditionCategoriesQuery,
    SearchConditionCategoriesQueryVariables
  >
> &
  TChildProps;
export function withSearchConditionCategories<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SearchConditionCategoriesQuery,
    SearchConditionCategoriesQueryVariables,
    SearchConditionCategoriesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SearchConditionCategoriesQuery,
    SearchConditionCategoriesQueryVariables,
    SearchConditionCategoriesProps<TChildProps>
  >(SearchConditionCategoriesDocument, {
    alias: "withSearchConditionCategories",
    ...operationOptions
  });
}
export const SearchConditionsDocument = gql`
  query SearchConditions($searchText: String!) {
    searchConditions(name: $searchText) {
      name
      id
      cpts {
        name
      }
      conditionCategory {
        name
        id
      }
    }
  }
`;

export const SearchConditionsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        SearchConditionsQuery,
        SearchConditionsQueryVariables
      >,
      "query"
    >,
    "variables"
  > & { variables: SearchConditionsQueryVariables }
) => (
  <ReactApollo.Query<SearchConditionsQuery, SearchConditionsQueryVariables>
    query={SearchConditionsDocument}
    {...props}
  />
);

export type SearchConditionsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<SearchConditionsQuery, SearchConditionsQueryVariables>
> &
  TChildProps;
export function withSearchConditions<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SearchConditionsQuery,
    SearchConditionsQueryVariables,
    SearchConditionsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SearchConditionsQuery,
    SearchConditionsQueryVariables,
    SearchConditionsProps<TChildProps>
  >(SearchConditionsDocument, {
    alias: "withSearchConditions",
    ...operationOptions
  });
}
export const SearchHospitalsDocument = gql`
  query SearchHospitals($searchText: String!) {
    searchHospitals(name: $searchText) {
      name
      id
    }
  }
`;

export const SearchHospitalsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        SearchHospitalsQuery,
        SearchHospitalsQueryVariables
      >,
      "query"
    >,
    "variables"
  > & { variables: SearchHospitalsQueryVariables }
) => (
  <ReactApollo.Query<SearchHospitalsQuery, SearchHospitalsQueryVariables>
    query={SearchHospitalsDocument}
    {...props}
  />
);

export type SearchHospitalsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<SearchHospitalsQuery, SearchHospitalsQueryVariables>
> &
  TChildProps;
export function withSearchHospitals<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SearchHospitalsQuery,
    SearchHospitalsQueryVariables,
    SearchHospitalsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SearchHospitalsQuery,
    SearchHospitalsQueryVariables,
    SearchHospitalsProps<TChildProps>
  >(SearchHospitalsDocument, {
    alias: "withSearchHospitals",
    ...operationOptions
  });
}
export type ChargesQueryVariables = {
  conditionId: Scalars["Int"];
  county?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type ChargesQuery = { __typename?: "Query" } & {
  drgs: Array<{ __typename?: "Drg" } & Pick<Drg, "complications">>;
  hospitalsCharges: Array<
    { __typename?: "Hospital" } & Pick<
      Hospital,
      "id" | "name" | "latitude" | "longitude" | "county" | "state" | "rating"
    > & {
        charges: Array<
          { __typename?: "Charge" } & Pick<Charge, "amount"> & {
              drg: Maybe<{ __typename?: "Drg" } & Pick<Drg, "complications">>;
            }
        >;
      }
  >;
};

export type CountiesQueryVariables = {
  state: Scalars["String"];
};

export type CountiesQuery = { __typename?: "Query" } & Pick<Query, "counties">;

export type StatesQueryVariables = {};

export type StatesQuery = { __typename?: "Query" } & Pick<Query, "states">;

export type AllSearchConditionCategoriesQueryVariables = {};

export type AllSearchConditionCategoriesQuery = { __typename?: "Query" } & {
  searchConditionCategories: Array<
    { __typename?: "ConditionCategory" } & Pick<
      ConditionCategory,
      "name" | "id"
    >
  >;
};

export type ConditionCategoryQueryVariables = {
  id: Scalars["Int"];
};

export type ConditionCategoryQuery = { __typename?: "Query" } & {
  conditionCategory: Maybe<
    { __typename?: "ConditionCategory" } & Pick<ConditionCategory, "name">
  >;
};

export type ConditionsQueryVariables = {
  id: Scalars["Int"];
};

export type ConditionsQuery = { __typename?: "Query" } & {
  conditions: Array<
    { __typename?: "Condition" } & Pick<Condition, "name" | "type" | "id">
  >;
};

export type ConditionQueryVariables = {
  id: Scalars["Int"];
};

export type ConditionQuery = { __typename?: "Query" } & {
  condition: Maybe<
    { __typename?: "Condition" } & Pick<Condition, "name"> & {
        cpts: Array<{ __typename?: "Cpt" } & Pick<Cpt, "name">>;
      }
  >;
};

export type AllConditionsQueryVariables = {};

export type AllConditionsQuery = { __typename?: "Query" } & {
  conditions: Array<
    { __typename?: "Condition" } & Pick<Condition, "name" | "type" | "id">
  >;
};

export type ConditionsChargesQueryVariables = {
  hospitalId: Scalars["Int"];
};

export type ConditionsChargesQuery = { __typename?: "Query" } & {
  hospital: Maybe<
    { __typename?: "Hospital" } & Pick<
      Hospital,
      | "id"
      | "name"
      | "latitude"
      | "longitude"
      | "address"
      | "city"
      | "state"
      | "zipCode"
      | "phoneNumber"
      | "rating"
      | "ratingNotes"
      | "inSet"
      | "hasCdm"
    >
  >;
  conditionsCharges: Array<
    { __typename?: "Condition" } & Pick<Condition, "id" | "name"> & {
        drgs: Array<
          { __typename?: "Drg" } & Pick<Drg, "complications"> & {
              charges: Array<
                { __typename?: "Charge" } & Pick<Charge, "amount">
              >;
            }
        >;
      }
  >;
};

export type HospitalsQueryVariables = {};

export type HospitalsQuery = { __typename?: "Query" } & {
  hospitals: Array<
    { __typename?: "Hospital" } & Pick<
      Hospital,
      "id" | "name" | "latitude" | "longitude"
    >
  >;
};

export type SearchConditionCategoriesQueryVariables = {
  searchText?: Maybe<Scalars["String"]>;
};

export type SearchConditionCategoriesQuery = { __typename?: "Query" } & {
  searchConditionCategories: Array<
    { __typename?: "ConditionCategory" } & Pick<
      ConditionCategory,
      "name" | "id"
    >
  >;
};

export type SearchConditionsQueryVariables = {
  searchText: Scalars["String"];
};

export type SearchConditionsQuery = { __typename?: "Query" } & {
  searchConditions: Array<
    { __typename?: "Condition" } & Pick<Condition, "name" | "id"> & {
        cpts: Array<{ __typename?: "Cpt" } & Pick<Cpt, "name">>;
        conditionCategory: { __typename?: "ConditionCategory" } & Pick<
          ConditionCategory,
          "name" | "id"
        >;
      }
  >;
};

export type SearchHospitalsQueryVariables = {
  searchText: Scalars["String"];
};

export type SearchHospitalsQuery = { __typename?: "Query" } & {
  searchHospitals: Array<
    { __typename?: "Hospital" } & Pick<Hospital, "name" | "id">
  >;
};

export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Charge = {
  id: Scalars["Int"];
  amount: Scalars["Float"];
  drg?: Maybe<Drg>;
  cpt?: Maybe<Cpt>;
  hospital: Hospital;
  complications?: Maybe<Scalars["String"]>;
};

export type Condition = {
  id: Scalars["Int"];
  name: Scalars["String"];
  type: ConditionType;
  conditionCategory: ConditionCategory;
  drgs: Array<Drg>;
  conditionCategoryId: Scalars["Int"];
  cpts: Array<Cpt>;
};

export type ConditionCategory = {
  id: Scalars["Int"];
  name: Scalars["String"];
  conditions: Array<Condition>;
};

export enum ConditionType {
  Medical = "medical",
  Surgical = "surgical"
}

export type Cpt = {
  id: Scalars["Int"];
  name: Scalars["String"];
  value: Scalars["String"];
  charges: Array<Charge>;
  conditions: Array<Condition>;
};

export type Drg = {
  id: Scalars["Int"];
  name: Scalars["String"];
  value: Scalars["String"];
  complications: Scalars["String"];
  charges: Array<Charge>;
  conditions: Array<Condition>;
};

export type Hospital = {
  id: Scalars["Int"];
  name: Scalars["String"];
  oshpdId?: Maybe<Scalars["String"]>;
  ccn: Scalars["String"];
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
  address: Scalars["String"];
  city: Scalars["String"];
  state: Scalars["String"];
  county: Scalars["String"];
  zipCode: Scalars["Int"];
  phoneNumber: Scalars["String"];
  rating?: Maybe<Scalars["Int"]>;
  ratingNotes?: Maybe<Scalars["String"]>;
  inSet: Scalars["Boolean"];
  hasCdm: Scalars["Boolean"];
  hasDrg: Scalars["Boolean"];
  charges: Array<Charge>;
};

export type Query = {
  searchConditionCategories: Array<ConditionCategory>;
  searchConditions: Array<Condition>;
  searchHospitals: Array<Hospital>;
  hospitalsCharges: Array<Hospital>;
  conditionsCharges: Array<Condition>;
  conditionCategory?: Maybe<ConditionCategory>;
  conditions: Array<Condition>;
  condition?: Maybe<Condition>;
  hospital?: Maybe<Hospital>;
  hospitals: Array<Hospital>;
  drgs: Array<Drg>;
  cpts: Array<Cpt>;
  cities: Array<Scalars["String"]>;
  counties: Array<Scalars["String"]>;
  states: Array<Scalars["String"]>;
};

export type QuerySearchConditionCategoriesArgs = {
  name?: Maybe<Scalars["String"]>;
};

export type QuerySearchConditionsArgs = {
  name: Scalars["String"];
};

export type QuerySearchHospitalsArgs = {
  name: Scalars["String"];
};

export type QueryHospitalsChargesArgs = {
  cptId?: Maybe<Scalars["Int"]>;
  conditionId?: Maybe<Scalars["Int"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
};

export type QueryConditionsChargesArgs = {
  hospitalId: Scalars["Int"];
};

export type QueryConditionCategoryArgs = {
  id: Scalars["Int"];
};

export type QueryConditionsArgs = {
  conditionCategoryId?: Maybe<Scalars["Int"]>;
};

export type QueryConditionArgs = {
  id: Scalars["Int"];
};

export type QueryHospitalArgs = {
  id: Scalars["Int"];
};

export type QueryDrgsArgs = {
  conditionId: Scalars["Int"];
};

export type QueryCptsArgs = {
  conditionId: Scalars["Int"];
};

export type QueryCitiesArgs = {
  state: Scalars["String"];
};

export type QueryCountiesArgs = {
  state: Scalars["String"];
};
