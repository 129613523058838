import React from "react";
import ReactGA from "react-ga";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import {
  createStyles,
  WithStyles,
  withStyles,
  MuiThemeProvider
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { Page, theme, searchTypes } from "./constants";
import Categories from "./containers/Categories";
import Conditions from "./containers/Conditions";
import Hospitals from "./containers/Hospitals";
import Search from "./containers/Search";
import About from "./containers/About";

export const client = new ApolloClient({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://compare-hospitals.appspot.com/"
      : "http://localhost:4000"
});

ReactGA.initialize("UA-133450824-1", {
  debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development"
});
ReactGA.pageview(window.location.pathname + window.location.search);

const styles = createStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: grey[200]
  }
});

const App = ({ classes }: WithStyles<typeof styles>) => (
  <ApolloProvider client={client}>
    <MuiThemeProvider theme={theme}>
      <Router>
        <div className={classes.root}>
          <Route
            exact
            path="/"
            render={() => (
              <Redirect from="/" to={`/${Page.search}/${searchTypes[0]}`} />
            )}
          />
          <Route
            path={`/${Page.search}/:type(${searchTypes.join("|")})`}
            component={Search}
          />
          <Route path={`/${Page.categories}/:id`} component={Categories} />
          <Route
            path={`/${Page.conditions}/:id/:state/:county/:breakdown`}
            component={Conditions}
          />
          <Route
            path={`/${Page.hospitals}/:id/:breakdown`}
            component={Hospitals}
          />
          <Route path={`/${Page.about}`} component={About} />
        </div>
      </Router>
    </MuiThemeProvider>
  </ApolloProvider>
);

export default withStyles(styles)(App);
