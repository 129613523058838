import React from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";

import { SelectOption } from "../../components/SelectUrlParam";
import SelectChargesParam from "../../components/SelectChargesParam";
import { ChargesPathProp, conditionTypeToName } from "../../constants";
import {
  AllConditionsComponent,
  AllConditionsQuery
} from "../../generated/graphql";

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    select: {
      [breakpoints.up("sm")]: {
        flex: 1,
        paddingRight: 22
      },
      [breakpoints.down("xs")]: {
        flex: "0 0 auto",
        paddingRight: 8
      },
      paddingLeft: 16,
      minWidth: 280
    }
  });

const getConditionOptions = (data: AllConditionsQuery | undefined) => {
  let codeOptions: SelectOption[] = [];
  if (data && data.conditions) {
    codeOptions = data.conditions.map(({ name, type, id }) => {
      return {
        value: id.toString(),
        label: `${name} (${conditionTypeToName[type]})`
      };
    });
  }
  return codeOptions;
};

const SelectCondition = ({ classes }: WithStyles<typeof styles>) => (
  <AllConditionsComponent>
    {({ data }) => (
      <SelectChargesParam
        className={classes.select}
        options={getConditionOptions(data)}
        placeholder="Condition"
        pathProp={ChargesPathProp.id}
      />
    )}
  </AllConditionsComponent>
);

export default withStyles(styles)(SelectCondition);
