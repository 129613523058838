import React from "react";
import Select from "react-select";
import { Props as ReactSelectProps } from "react-select/lib/Creatable";

import { theme } from "../constants";

interface StylesState {
  isFocused: boolean;
  isDisabled: boolean;
  isSelected: boolean;
}

export interface SelectOption {
  label: string;
  value: string;
}

class SingleValueSelect extends Select<SelectOption> {}

const customStyles = {
  option: (provided: React.CSSProperties, state: StylesState) => {
    const styleOverride = {
      color: "black",
      backgroundColor: "white",
      ":active": {
        color: "white",
        backgroundColor: theme.palette.secondary.light
      }
    };
    if (state.isSelected) {
      styleOverride.color = "white";
      styleOverride.backgroundColor = theme.palette.secondary.light;
    } else if (state.isFocused) {
      styleOverride.backgroundColor = theme.palette.action.hover;
    }

    return {
      ...provided,
      ...styleOverride
    };
  },
  control: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 0,
    border: 0
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    color: "white"
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    color: "white"
  }),
  singleValue: (provided: React.CSSProperties, state: StylesState) => ({
    ...provided,
    color: "white",
    opacity: state.isDisabled ? 0.5 : 1
  })
};

interface Props extends ReactSelectProps<SelectOption> {
  options: SelectOption[];
}

const SelectUrlParam = ({
  options,
  isDisabled,
  className,
  ...other
}: Props) => (
  <div className={className}>
    <SingleValueSelect
      styles={customStyles}
      options={options}
      isDisabled={isDisabled}
      menuPosition="fixed"
      {...other}
    />
  </div>
);

export default SelectUrlParam;
