import React from "react";
import { ListItem, ListItemText } from "@material-ui/core";

interface Props {
  primary: string;
  secondary?: string;
}

export default ({ primary, secondary }: Props) => (
  <ListItem button>
    <ListItemText primary={primary} secondary={secondary} />
  </ListItem>
);
