import React from "react";
import {
  ListItem,
  ListItemText,
  Typography,
  Divider,
  createStyles,
  withStyles,
  WithStyles,
  Theme,
  List
} from "@material-ui/core";
import { withRouter, RouteComponentProps, generatePath } from "react-router";
import ReactGA from "react-ga";

import { CategoriesPathProps } from ".";
import { parseId } from "../../utils";
import {
  AllSearchConditionCategoriesComponent,
  AllSearchConditionCategoriesQuery
} from "../../generated/graphql";

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      marginBottom: 20
    },
    header: {
      marginLeft: 16
    },
    selectedListItemText: {
      color: "white"
    },
    selectedListItem: {
      backgroundColor: `${palette.secondary.light} !important`
    }
  });

const getCategories = (data: AllSearchConditionCategoriesQuery | undefined) =>
  data
    ? data.searchConditionCategories
      ? data.searchConditionCategories
      : []
    : [];

interface Props
  extends WithStyles<typeof styles>,
    RouteComponentProps<CategoriesPathProps> {
  handleClick: () => void;
}

const CategoriesList = ({ handleClick, history, match, classes }: Props) => (
  <List component="nav">
    <AllSearchConditionCategoriesComponent>
      {({ data }) =>
        getCategories(data).length ? (
          <div className={classes.root}>
            <Typography variant="h6" gutterBottom className={classes.header}>
              Categories
            </Typography>
            <Divider />
            {getCategories(data).map((category, index: number) => (
              <div key={index}>
                <ListItem
                  button
                  selected={parseId(match.params.id) === category.id}
                  onClick={() => {
                    handleClick();
                    const newUrl = generatePath(match.path, {
                      id: category.id
                    });
                    ReactGA.pageview(newUrl);
                    history.push(newUrl);
                  }}
                  classes={
                    parseId(match.params.id) === category.id
                      ? { root: classes.selectedListItem }
                      : undefined
                  }
                >
                  <ListItemText
                    primary={category.name}
                    classes={
                      parseId(match.params.id) === category.id
                        ? { primary: classes.selectedListItemText }
                        : undefined
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            ))}
          </div>
        ) : null
      }
    </AllSearchConditionCategoriesComponent>
  </List>
);

export default withStyles(styles)(withRouter(CategoriesList));
