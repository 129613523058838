import React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  Paper,
  Typography
} from "@material-ui/core";
import { TooltipProps } from "recharts";

import HospitalRating from "../../../components/HospitalRating";

const styles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: 8
  },
  hospitalRatingIcon: {
    fontSize: 16
  },
  costRating: {
    marginTop: 8
  }
});

const CustomTooltip = ({
  active,
  payload,
  classes
}: TooltipProps & WithStyles<typeof styles>) => {
  if (active && payload) {
    return (
      <Paper className={classes.root}>
        <Typography variant="subtitle2">{payload[0].payload.n}</Typography>
        <HospitalRating
          hospital={{ rating: payload[0].payload.r }}
          classes={{ icon: classes.hospitalRatingIcon }}
        />
        <Typography variant="caption" className={classes.costRating}>
          Cost Rating: {Number(payload[0].payload.z).toFixed(2)}
        </Typography>
        <Typography variant="caption">
          (lower cheaper, higher more expensive)
        </Typography>
      </Paper>
    );
  }

  return null;
};

export default withStyles(styles)(CustomTooltip);
