import React from "react";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ReactGA from "react-ga";
import { LinkProps } from "@material-ui/core/Link";

interface Props extends LinkProps {
  to?: string;
}

export default ({ to, onClick, children, classes, ...other }: Props) => (
  <Link
    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (to) {
        ReactGA.pageview(to);
      }
      if (onClick) {
        onClick(event);
      }
    }}
    color="secondary"
    component={
      to ? (props: any) => <RouterLink {...props} to={to} /> : undefined
    }
    style={{ cursor: "pointer" }}
    {...other}
  >
    {children}
  </Link>
);
