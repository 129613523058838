import React, { ReactNode } from "react";
import { components } from "react-select";
import AsyncSelect, { Props as AsyncSelectProps } from "react-select/lib/Async";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles, createStyles, Theme } from "@material-ui/core";

import { theme } from "../../constants";

interface StylesState {
  isFocused: boolean;
  isDisabled: boolean;
  isSelected: boolean;
}

export interface SelectOption {
  label: ReactNode;
  value: string;
}

export interface OptGroup {
  label: string;
  options: SelectOption[];
}

const styles = ({ palette }: Theme) =>
  createStyles({
    searchIcon: { position: "absolute", left: 6, color: palette.grey[800] }
  });

const ValueContainer = ({ classes, children, ...props }: any) =>
  components.ValueContainer && (
    <components.ValueContainer {...props}>
      {!!children && <SearchIcon className={classes.searchIcon} />}
      {children}
    </components.ValueContainer>
  );

const StyledValueContainer = withStyles(styles)(ValueContainer);

class SingleValueAsyncSelect extends AsyncSelect<SelectOption> {}

const customStyles = {
  valueContainer: (provided: React.CSSProperties) => ({
    ...provided,
    paddingLeft: 32
  }),
  option: (provided: React.CSSProperties, state: StylesState) => {
    const styleOverride = {
      padding: 0,
      color: "black",
      backgroundColor: "white",
      ":active": {
        color: "white",
        backgroundColor: theme.palette.secondary.light
      }
    };
    if (state.isSelected) {
      styleOverride.color = "white";
      styleOverride.backgroundColor = theme.palette.secondary.light;
    } else if (state.isFocused) {
      styleOverride.backgroundColor = theme.palette.action.hover;
    }

    return {
      ...provided,
      ...styleOverride
    };
  },
  control: (provided: React.CSSProperties) => ({
    ...provided,
    borderRadius: 0,
    border: 0
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    color: theme.palette.grey[800]
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    color: theme.palette.grey[800]
  }),
  singleValue: (provided: React.CSSProperties, state: StylesState) => ({
    ...provided,
    color: theme.palette.grey[800],
    opacity: state.isDisabled ? 0.5 : 1
  }),
  dropdownIndicator: (provided: React.CSSProperties) => ({
    ...provided,
    color: theme.palette.grey[800]
  }),
  indicatorSeparator: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: theme.palette.grey[800]
  })
};

export default ({ ...other }: AsyncSelectProps<OptGroup>) => (
  <SingleValueAsyncSelect
    styles={customStyles}
    // @ts-ignore
    components={{ ValueContainer: StyledValueContainer }}
    {...other}
  />
);
