import React, { Component } from "react";
import {
  Dialog,
  withMobileDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton
} from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import HelpIcon from "@material-ui/icons/Help";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import { WithWidth } from "@material-ui/core/withWidth";

import { parseId } from "../../utils";
import { ConditionComponent, ConditionQuery } from "../../generated/graphql";

const getCptNames = (data: ConditionQuery | undefined) => {
  if (!data || !data.condition || !data.condition.cpts) {
    return [];
  }
  return data.condition.cpts.map(cpt => cpt.name);
};

enum ConditionsPathProp {
  id = "id"
}

type ConditionsPathProps = { [pathProp in ConditionsPathProp]?: string };

interface State {
  open: boolean;
}

class CptsDialog extends Component<
  RouteComponentProps<ConditionsPathProps> & InjectedProps & Partial<WithWidth>,
  State
> {
  state = { open: false };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { match, fullScreen } = this.props;
    const { open } = this.state;
    return (
      <>
        <IconButton onClick={this.handleClickOpen}>
          <HelpIcon />
        </IconButton>
        <ConditionComponent
          variables={{ id: parseId(match.params.id) as number }}
          skip={!match.params.id || !parseId(match.params.id)}
        >
          {({ data }) =>
            getCptNames(data).length ? (
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={this.handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  Procedures included in "
                  {data && data.condition && data.condition.name}"
                </DialogTitle>
                <DialogContent>
                  <ul>
                    {getCptNames(data).map((cptName, index) => (
                      <li key={index}>
                        <Typography variant="body2">{cptName}</Typography>
                      </li>
                    ))}
                  </ul>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary" autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null
          }
        </ConditionComponent>
      </>
    );
  }
}

export default withMobileDialog()(withRouter(CptsDialog));
