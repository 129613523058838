import React from "react";
import { ValueType } from "react-select/lib/types";
import { withRouter, RouteComponentProps } from "react-router";
import ReactGA from "react-ga";

import { client } from "../../App";
import { SearchPathProps } from ".";
import SearchBar, { SelectOption, OptGroup } from "./SearchBar";
import SearchListItem from "./SearchListItem";
import { getHospitalsPath } from "../../utils";
import {
  SearchHospitalsDocument,
  SearchHospitalsQuery,
  SearchHospitalsQueryVariables
} from "../../generated/graphql";

const getHospitalsOptions = (data: SearchHospitalsQuery | undefined) =>
  data
    ? data.searchHospitals
      ? data.searchHospitals.map(hospital => ({
          label: <SearchListItem primary={hospital.name} />,
          value: getHospitalsPath({
            id: hospital.id.toString()
          })
        }))
      : []
    : [];

const SearchHospitals = ({ history }: RouteComponentProps<SearchPathProps>) => (
  <SearchBar
    placeholder="Search hospitals..."
    loadOptions={async (searchText: string) => {
      const { data } = await client.query<
        SearchHospitalsQuery,
        SearchHospitalsQueryVariables
      >({
        query: SearchHospitalsDocument,
        variables: { searchText }
      });
      return getHospitalsOptions(data);
    }}
    onChange={(selectOption: ValueType<SelectOption | OptGroup>) => {
      if (
        !selectOption ||
        Array.isArray(selectOption) ||
        !("value" in selectOption)
      ) {
        return;
      }
      ReactGA.pageview(selectOption.value);
      history.push(selectOption.value);
    }}
  />
);

export default withRouter(SearchHospitals);
