import React, { Component } from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme
} from "@material-ui/core/styles";
import { SortDirectionType, SortDirection } from "react-virtualized";
import { withRouter, RouteComponentProps } from "react-router";
import { Hidden, withWidth } from "@material-ui/core";
import { isWidthUp, WithWidth } from "@material-ui/core/withWidth";

import ChargesTable, { averageChargeDataKey } from "./ChargesTable";
import { client } from "../../App";
import { parseId } from "../../utils";
import HospitalsMap from "../../components/HospitalsMap";
import AboutHospital from "./AboutHospital";
import Header from "../../components/Header";
import ToggleBreakdown from "../../components/ToggleBreakdown";
import { BreakdownType } from "../../constants";
import DataInfo from "./DataInfo";
import {
  ConditionsChargesDocument,
  ConditionsChargesQuery,
  ConditionsChargesQueryVariables
} from "../../generated/graphql";

const styles = ({ mixins, breakpoints }: Theme) =>
  createStyles({
    root: {
      [breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
        marginTop: 16,
        marginBottom: 8,
        marginRight: 16,
        marginLeft: 16
      },
      [breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 16,
        marginBottom: 16,
        marginRight: 32,
        marginLeft: 32
      },
      flex: 1,
      display: "flex"
    },
    toolbar: { ...mixins.toolbar, height: 64 },
    table: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      minHeight: 400,
      width: "100%"
    },
    info: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 32
    },
    map: { width: 400 }
  });

export enum HospitalsPathProp {
  id = "id",
  breakdown = "breakdown"
}

export type HospitalsPathProps = { [pathProp in HospitalsPathProp]: string };

export interface SortArgs {
  sortBy: string;
  sortDirection: SortDirectionType;
}

export type ConditionsCharges = ConditionsChargesQuery["conditionsCharges"];
export type Condition = ConditionsCharges[0];

interface State {
  sortBy?: string;
  sortDirection: SortDirectionType;
  conditionsCharges: ConditionsChargesQuery["conditionsCharges"];
  complicationsList: string[];
  hospital?: ConditionsChargesQuery["hospital"];
  highlightedRowIndex?: number;
}

type Props = WithStyles<typeof styles> &
  RouteComponentProps<HospitalsPathProps> &
  WithWidth;

class Hospitals extends Component<Props, State> {
  state: State = {
    sortDirection: SortDirection.DESC,
    conditionsCharges: [],
    complicationsList: []
  };

  sort = ({ sortBy, sortDirection }: SortArgs) => {
    let { conditionsCharges } = this.state;
    conditionsCharges = this.sortConditions(
      conditionsCharges,
      sortBy,
      sortDirection
    );

    this.setState({ sortBy, sortDirection, conditionsCharges });
  };

  findAmountForDataKey = (condition: Condition, dataKey: string) => {
    let amount: number | undefined;
    if (dataKey === averageChargeDataKey) {
      amount =
        condition.drgs
          .map(drg => drg.charges[0].amount)
          .reduce((sum, current) => sum + current) / condition.drgs.length;
    } else {
      const drg = condition.drgs.find(drg => drg.complications === dataKey);
      amount = drg && drg.charges[0].amount;
    }
    return amount;
  };

  sortConditions = (
    conditionsCharges: ConditionsCharges,
    sortBy: string,
    sortDirection: SortDirectionType
  ) => {
    const notReportingAmount =
      sortDirection === SortDirection.DESC
        ? Number.MAX_SAFE_INTEGER
        : Number.MIN_SAFE_INTEGER;
    return conditionsCharges.sort((conditionA, conditionB) => {
      const chargeA =
        this.findAmountForDataKey(conditionA, sortBy) || notReportingAmount;
      const chargeB =
        this.findAmountForDataKey(conditionB, sortBy) || notReportingAmount;

      return sortDirection === SortDirection.DESC
        ? chargeA - chargeB
        : chargeB - chargeA;
    });
  };

  updatedConditionsCharges = async () => {
    const { width, match } = this.props;
    const { id } = match.params;
    if (!id || !parseId(id)) {
      this.setState({
        conditionsCharges: [],
        complicationsList: [],
        sortBy: undefined
      });
      return;
    }
    const { data } = await client.query<
      ConditionsChargesQuery | undefined,
      ConditionsChargesQueryVariables
    >({
      query: ConditionsChargesDocument,
      variables: {
        hospitalId: parseId(id) as number
      }
    });
    const conditionsCharges = (data && data.conditionsCharges) || [];
    const complicationsList =
      [
        ...new Set(
          conditionsCharges
            .map(condition => condition.drgs.map(drg => drg.complications))
            .flat()
        )
      ].sort() || [];
    const sortBy =
      complicationsList.length > 1 &&
      match.params.breakdown === BreakdownType.breakdown &&
      isWidthUp("md", width)
        ? complicationsList[0]
        : averageChargeDataKey;
    this.setState({
      hospital: data && data.hospital,
      conditionsCharges: this.sortConditions(
        conditionsCharges,
        sortBy,
        SortDirection.DESC
      ),
      complicationsList,
      sortBy,
      sortDirection: SortDirection.DESC
    });
  };

  async componentDidMount() {
    this.updatedConditionsCharges();
  }

  async componentDidUpdate(prevProps: Props) {
    const { width, match } = this.props;
    const { id, breakdown } = match.params;
    const prevParams = prevProps.match.params;
    if (id !== prevParams.id) {
      this.updatedConditionsCharges();
    }
    if (breakdown !== prevParams.breakdown || width !== prevProps.width) {
      const { complicationsList } = this.state;
      const sortBy =
        complicationsList.length > 1 &&
        match.params.breakdown === BreakdownType.breakdown &&
        isWidthUp("md", width)
          ? complicationsList[0]
          : averageChargeDataKey;
      this.sort({ sortBy, sortDirection: SortDirection.DESC });
    }
  }

  render() {
    const { width, classes } = this.props;
    const {
      hospital,
      conditionsCharges,
      complicationsList,
      sortBy,
      sortDirection
    } = this.state;
    return (
      <>
        <Header />
        <div className={classes.toolbar} />
        <div className={classes.root}>
          <div className={classes.table}>
            <Hidden lgUp>
              {hospital ? <AboutHospital hospital={hospital} /> : null}
            </Hidden>
            {complicationsList.length > 1 && isWidthUp("md", width) ? (
              <ToggleBreakdown title="Reported conditions" hasBreakdownToggle />
            ) : null}
            {!hospital || conditionsCharges.length ? (
              <ChargesTable
                conditions={conditionsCharges}
                complicationsList={complicationsList}
                sort={this.sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
              />
            ) : (
              <DataInfo hospital={hospital} />
            )}
          </div>
          <Hidden mdDown>
            <div className={classes.info}>
              {hospital ? <AboutHospital hospital={hospital} /> : null}
              <HospitalsMap
                classes={{ root: classes.map }}
                hospitals={hospital ? [hospital] : []}
                highlightedHospitalId={hospital && hospital.id}
                hasCounties
                hasUpdateLatLong
              />
            </div>
          </Hidden>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withWidth()(withRouter(Hospitals)));
