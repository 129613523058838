import React, { ReactNode } from "react";
import AppBar from "@material-ui/core/AppBar";
import {
  Typography,
  Toolbar,
  createStyles,
  WithStyles,
  withStyles,
  Button
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import Link from "./Link";
import { Page, searchTypes } from "../constants";

const styles = createStyles({
  root: {},
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  toolbarLeft: {
    display: "flex",
    alignItems: "center"
  }
});

// https://next.material-ui.com/demos/buttons/#third-party-routing-library
// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
const ButtonLink = React.forwardRef((props, ref) => (
  // @ts-ignore
  <RouterLink {...props} innerRef={ref} />
));

interface Props extends WithStyles<typeof styles> {
  children?: ReactNode;
  ToolbarIcon?: ReactNode;
  padding?: boolean;
}

const Header = ({ ToolbarIcon, padding, children, classes }: Props) => (
  <AppBar
    position="fixed"
    className={classes.root}
    style={
      padding
        ? {
            paddingBottom: 16
          }
        : undefined
    }
  >
    <Toolbar className={classes.toolbar}>
      <div className={classes.toolbarLeft}>
        {ToolbarIcon}
        <Link to={`/${Page.search}/${searchTypes[0]}`} color="inherit">
          <Typography variant="h6" color="inherit">
            Compare Hospitals
          </Typography>
        </Link>
      </div>
      <Button
        color="inherit"
        // https://next.material-ui.com/demos/buttons/#third-party-routing-library
        // @ts-ignore
        component={ButtonLink}
        to={`/${Page.about}`}
      >
        About
      </Button>
    </Toolbar>
    {children}
  </AppBar>
);

export default withStyles(styles)(Header);
