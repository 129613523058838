import React from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme
} from "@material-ui/core/styles";

import { Feature, HospitalProperties } from ".";

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      margin: 8,
      padding: 4,
      background: palette.grey[700],
      opacity: 0.9,
      color: "white",
      maxWidth: 400,
      fontSize: 10,
      zIndex: 9,
      pointerEvents: "none"
    }
  });

interface Props extends WithStyles<typeof styles> {
  hoveredFeature: Feature<HospitalProperties>;
  x?: number;
  y?: number;
}

const CountyTooltip = ({ classes, hoveredFeature, x, y }: Props) => (
  <div className={classes.root} style={{ left: x, top: y }}>
    {hoveredFeature.properties.name}
  </div>
);

export default withStyles(styles)(CountyTooltip);
