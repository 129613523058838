import React from "react";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";

import SelectCounty from "../../components/SelectCounty";
import ChargesContent from "../../components/ChargesContent";
import SelectState from "../../components/SelectState";
import SelectCondition from "./SelectCondition";
import Header from "../../components/Header";

const styles = createStyles({
  filters: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto"
  }
});

const Conditions = ({ classes }: WithStyles<typeof styles>) => (
  <>
    <Header padding>
      <div className={classes.filters}>
        <SelectCondition />
        <SelectState />
        <SelectCounty />
      </div>
    </Header>
    <ChargesContent />
  </>
);

export default withStyles(styles)(Conditions);
