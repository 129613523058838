import React from "react";
import {
  Tooltip,
  Typography,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

const styles = createStyles({
  header: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    fontSize: 16,
    marginLeft: 2
  }
});

interface Props extends WithStyles<typeof styles> {
  complications: string;
}

const complicationsDisplay: { [index: string]: string } = {
  mcc: "AVG WITH MCC",
  cc: "AVG WITH CC",
  either: "AVG WITH MCC/CC",
  none: "AVG WITHOUT MCC/CC",
  not_applicable: "N/A"
};

const complicationsTitle: { [index: string]: string } = {
  mcc: "Major Complications or Comorbidities",
  cc: "Complications or Comorbidities",
  either: "Complications or Comorbidities",
  none: "No Complications or Comorbidities",
  not_applicable: "N/A for this Condition"
};

const ComplicationsTooltip = ({ complications, classes }: Props) => (
  <Tooltip
    title={
      <>
        <Typography color="inherit">
          {complicationsTitle[complications]}
        </Typography>
        {complications !== "not_applicable" ? (
          <>
            <p>
              <b>Complications:</b> other conditions that develop during
              treatment.
            </p>
            <p>
              <b>Comorbidities:</b> pre-existing conditions before treatment.
            </p>
          </>
        ) : null}
      </>
    }
  >
    <div className={classes.header}>
      <div>{complicationsDisplay[complications]}</div>
      <HelpIcon className={classes.icon} />
    </div>
  </Tooltip>
);

export default withStyles(styles)(ComplicationsTooltip);
