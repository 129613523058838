import React, { Component } from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Drawer,
  Theme,
  Hidden
} from "@material-ui/core";

import ConditionsTable from "./ConditionsTable";
import Header from "./Header";
import CategoryDescription from "./CategoryDescription";
import CategoriesList from "./CategoriesList";

const smallDrawerWidth = 260;
const drawerWidth = 360;

const styles = ({ mixins, breakpoints }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1
    },
    drawer: {
      [breakpoints.down("xs")]: {
        width: smallDrawerWidth
      },
      [breakpoints.up("sm")]: {
        width: drawerWidth
      },
      flexShrink: 0,
      display: "flex",
      flexDirection: "column"
    },
    drawerPaper: {
      [breakpoints.down("xs")]: {
        width: smallDrawerWidth
      },
      [breakpoints.up("sm")]: {
        width: drawerWidth
      }
    },
    contentClass: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      [breakpoints.down("sm")]: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 16,
        paddingLeft: 16
      },
      [breakpoints.up("md")]: {
        paddingTop: 32,
        paddingBottom: 32,
        paddingRight: 64,
        paddingLeft: 64
      }
    },
    outpatientLink: {
      marginLeft: 16,
      marginTop: 20,
      marginBottom: 40
    },
    toolbar: mixins.toolbar
  });

export enum CategoriesPathProp {
  id = "id"
}

export type CategoriesPathProps = { [pathProp in CategoriesPathProp]: string };

interface State {
  mobileOpen: boolean;
}

class Categories extends Component<WithStyles<typeof styles, true>, State> {
  state: State = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { theme, classes } = this.props;
    const { mobileOpen } = this.state;
    return (
      <>
        <Header handleDrawerToggle={this.handleDrawerToggle} />
        <div className={classes.root}>
          <Hidden smDown>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <div className={classes.toolbar} />

              <CategoriesList handleClick={this.handleDrawerToggle} />
            </Drawer>
          </Hidden>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <CategoriesList handleClick={this.handleDrawerToggle} />
            </Drawer>
          </Hidden>

          <div className={classes.contentClass}>
            <div className={classes.toolbar} />
            <CategoryDescription />
            <ConditionsTable />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Categories);
